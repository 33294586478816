<template>
    <heading-level class="mobile-layout" :style="`--weather-prompt-height: ${weatherPromptHeight}px`">
        <template v-if="!hideMainNav">
            <v-app-bar color="secondary" dark class="title-bar" height="auto">
                <home-or-back-button class="home-or-back" :icon-only="Boolean(headingTitle)" />

                <base-button text class="d-sr-only-focusable" @click="$refs.mainNav?.focus()">{{ $t('nav.skipToMainNav') }}</base-button>
                <base-button text class="d-sr-only-focusable" @click="$refs.main?.focus()">{{ $t('nav.skipToMain') }}</base-button>

                <v-toolbar-title role="heading" aria-level="1" style="text-align: center;">
                    {{ headingTitle }}
                </v-toolbar-title>

                <template v-if="userChecked">
                    <auth-button v-if="!currentUser" text>
                        {{ $t('actions.login') }}
                    </auth-button>

                    <template v-else>
                        <base-button v-if="currentUser" icon aria-label="Account" @click="showUserMenu = true">
                            <user-avatar :user="currentUser" :image-only="true" size="medium" />
                        </base-button>
                    </template>
                </template>
            </v-app-bar>

            <v-navigation-drawer v-if="currentUser" v-model="showUserMenu" fixed right temporary width="min(85vw, 20rem)">
                <div style="height: calc(env(safe-area-inset-top) / 2);" />
                <the-account-navigation :class="{'my-6': platform !== 'web' }" icons />
            </v-navigation-drawer>
        </template>

        <main class="mobile-content-wrapper">
            <app-banner class="site-app-banner" />
            <language-callout />
            <historical-weather-prompt @resize="weatherPromptHeight = $event" />
            <survey-requests-prompt-banner />
            <span id="main-content-focus-point" ref="main" class="d-sr-only" tabindex="-1">{{ $t('nav.mainContent') }}</span>
            <slot />
            <div v-if="!showBottomNavigation" style="height: env(safe-area-inset-bottom);" />
        </main>

        <nav v-if="showBottomNavigation" ref="mainNav" class="bottom-navigation" tabindex="-1">
            <!-- Without a link home, other active links don't seem to clear correctly. Vuetify bug? -->
            <router-link :to="{ name: 'home' }" style="display: none;">Home</router-link>

            <router-link v-if="showClientLinks" :to="{ name: 'client-map-view' }">
                <div class="nav-button">
                    <mobile-nav-icon which="map" />
                    <div>{{ $t('nav.map') }}</div>
                </div>
            </router-link>

            <router-link :to="{ name: 'posts' }">
                <div class="nav-button">
                    <mobile-nav-icon which="sightings" />
                    <div>{{ $t('routes.posts') }}</div>
                </div>
            </router-link>

            <router-link :to="{ name: 'projects-index' }">
                <div class="nav-button">
                    <mobile-nav-icon which="projects" />
                    <div>{{ $t('routes.projects') }}</div>
                </div>
            </router-link>

            <router-link :to="{ name: 'posts.create' }">
                <div class="nav-button">
                    <mobile-nav-icon which="add-sighting" />
                    <div>{{ $t('nav.post') }}</div>
                </div>
            </router-link>

            <router-link v-if="showClientLinks" :to="{ name: 'people' }">
                <div class="nav-button">
                    <mobile-nav-icon which="people" />
                    <div>{{ $t('nav.people') }}</div>
                </div>
            </router-link>

            <router-link v-if="!showClientLinks" :to="{ name: 'actions' }" @click="trackActionsPageLinkClick">
                <div class="nav-button">
                    <v-badge :value="$store.getters.hasNewActionsPageContent" bordered dot :offset-x="9" :offset-y="9">
                        <mobile-nav-icon which="actions" />
                    </v-badge>
                    <div>{{ $t('nav.actions') }}</div>
                </div>
            </router-link>

            <button type="button" @click="showMore = true">
                <div class="nav-button">
                    <mobile-nav-icon which="more" />
                    <div>{{ $t('nav.more') }}</div>
                </div>
            </button>
        </nav>

        <v-navigation-drawer v-if="showBottomNavigation" v-model="showMore" fixed right width="max(85vw)" temporary tag="nav" :aria-label="$t('nav.more')">
            <div style="height: env(safe-area-inset-top);" />

            <v-list class="my-6">
                <v-list-item v-if="showClientLinks">
                    <base-button text block color="primary" :to="{ name: 'actions' }" @click="trackActionsPageLinkClick">
                        <v-badge :value="$store.getters.hasNewActionsPageContent" bordered dot inline>
                            {{ $t('nav.actions') }}
                        </v-badge>
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" :to="{ name: 'investigations' }">
                        {{ $t('routes.investigations') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" :to="{ name: 'stories' }">
                        {{ $t('routes.stories') }}
                    </base-button>
                </v-list-item>

                <v-list-item v-if="!showClientLinks">
                    <base-button text block color="primary" to="/getting-started">
                        {{ $t('nav.gettingStarted') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" href="https://partners.iseechange.com/about-us">
                        {{ $t('nav.about') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" to="/about/contact">
                        {{ $t('nav.contact') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" href="https://partners.iseechange.com/" target="partners">
                        {{ $t('nav.partnerWithUs') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" to="/terms">
                        {{ $t('nav.terms') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button text block color="primary" to="/privacy">
                        {{ $t('nav.privacy') }}
                    </base-button>
                </v-list-item>

                <v-list-item>
                    <base-button href="https://iseechange.notion.site/Jobs-at-ISeeChange-120b4c78301442169fdd104d814b1a4e" target="_blank" text block color="primary">
                        {{ $t('nav.careers') }}
                    </base-button>
                </v-list-item>

                <the-language-select v-slot="{ attrs, on }">
                    <v-list-item>
                        <base-button text block color="primary" v-bind="attrs" v-on="on">
                            {{ $t('footer.preferredlanguage') }}
                        </base-button>
                    </v-list-item>
                </the-language-select>

                <v-list-item v-if="$store.getters.coveredByMiamiA11y">
                    <base-button text block color="primary" to="/miami-accessibility">
                        {{ $t('nav.accessibility') }}
                    </base-button>
                </v-list-item>

                <v-list-item class="copyright">
                    <base-button text block color="secondary">
                        <release-info>&copy; {{ new Date().getFullYear() }} ISeeChange</release-info>
                    </base-button>

                    <experiments-menu />
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <onboarding v-if="isApp || forceOnboardingToShow" />
    </heading-level>
</template>
<script lang="ts">
import AuthButton from '@/components/AuthButton.vue';
import ExperimentsMenu from '@/components/ExperimentsMenu.vue';
import HistoricalWeatherPrompt from '@/components/HistoricalWeatherPrompt/Index.vue';
import HomeOrBackButton from '@/components/HomeOrBackButton.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import AppBanner from '@/components/mobile/AppBanner.vue';
import MobileNavIcon from '@/components/MobileNavIcon.vue';
import Onboarding from '@/components/Onboarding.vue';
import ReleaseInfo from '@/components/ReleaseInfo.vue';
import SurveyRequestsPromptBanner from '@/components/Survey/RequestsPromptBanner.vue';
import TheAccountNavigation from '@/components/TheAccountNavigation.vue';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { trackActionsPageEvent } from '@/tracking';
import { CurrentUser } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';
import { Keyboard } from '@capacitor/keyboard';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        AppBanner,
        AuthButton,
        ExperimentsMenu,
        Heading,
        HeadingLevel,
        HistoricalWeatherPrompt,
        HomeOrBackButton,
        LanguageCallout,
        MobileNavIcon,
        Onboarding,
        ReleaseInfo,
        SurveyRequestsPromptBanner,
        TheAccountNavigation,
        TheLanguageSelect,
        UserAvatar,
    },
    data() {
        return {
            activeTab: null,
            showUserMenu: false,
            showMore: false,
            weatherPromptHeight: 0,
            prevRoute: null,
            keyboardVisible: false,
            keyboardListenerHandles: null as any[] | null,
        };
    },
    computed: {
        platform(): string {
            return this.$store.state.platform;
        },
        isApp(): boolean {
            return ['ios', 'android'].includes(this.platform);
        },
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        userChecked(): boolean {
            return this.$store.state.account.autoAuthStatus !== null;
        },
        headingTitle(): TranslateResult {
            if (this.$route.name && this.$route.name !== 'home') {
                // vue-i18n doesn't allow dots in keys.
                const routeWithoutDots = this.$route.name.split('.').join('_');
                if (this.$te(`routes.${routeWithoutDots}`)) {
                    return this.$t(`routes.${routeWithoutDots}`);
                } else {
                    const routePrefix = this.$route.name.split('.')[0];
                    if (this.$te(`routes.${routePrefix}`)) {
                        return this.$t(`routes.${routePrefix}`);
                    }
                }
            }
            return '';
        },
        hideMainNav(): boolean {
            return !this.currentUser && this.$route.matched.some(record => record.meta.hideMainNavWithNoUser);
        },
        showBottomNavigation(): boolean {
            return Boolean(!this.hideMainNav && this.$route && (this.$route.meta && !this.$route.meta.hideMobileNav) && !this.keyboardVisible);
        },
        showClientLinks(): boolean {
            return (this.currentUser?.clientGroups?.length ?? 0) !== 0;
        },
        iconDestination() {
            if (this.$route.meta?.baseRoute) {
                return { name: this.$route.meta.baseRoute };
            } else if (this.platform !== 'web') {
                // Posts are the home of the app.
                return { name: 'posts' };
            } else {
                return { name: 'home' };
            }
        },
        iconGoesBack(): boolean {
            return Boolean(this.$route.meta?.baseRoute);
        },
        forceOnboardingToShow(): boolean {
            return location.search.includes('show-mobile-onboarding');
        },
    },
    watch: {
        $route() {
            this.showMore = false;
        },
        currentUser() {
            this.showUserMenu = false;
        },
        userChecked() {
            // TODO: Call this when onboarding can show up without the rest of the UI (refactor it to not be a dialog).
            // For now it'll time out on its own.
            // SplashScreen.hide();
        },
    },
    async mounted() {
        document.documentElement.dataset.mobileLayout = '1';

        window.addEventListener('keydown', this.handleKeydown);

        try {
            await Keyboard.setAccessoryBarVisible({ isVisible: true });

            this.keyboardListenerHandles = [
                await Keyboard.addListener('keyboardDidShow', this.handleKeyboardShow),
                await Keyboard.addListener('keyboardDidHide', this.handleKeyboardHide),
            ];
        } catch (error: any) {
            if (!`${error?.message}`?.toUpperCase().includes('NOT IMPLEMENTED')) {
                throw error;
            }
        }
    },

    destroyed() {
        delete document.documentElement.dataset.mobileLayout;

        window.removeEventListener('keydown', this.handleKeydown);
        this.keyboardListenerHandles?.forEach(handle => handle?.remove());
    },

    methods: {
        handleKeydown(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.showUserMenu = false;
                this.showMore = false;
            }
        },

        async handleKeyboardShow() {
            this.keyboardVisible = true;
            await this.$nextTick();
            window.dispatchEvent(new Event('resize'));
            setTimeout(() => {
                // If a field in an iframe is focused, the iframe becomes this document's activeElement, so don't do anything.
                if (document.activeElement instanceof HTMLIFrameElement) return;
                this.scrollToFocusedElementIfNecessary({ target: document.activeElement } as FocusEvent);
            }, 250);
        },

        async handleKeyboardHide() {
            this.keyboardVisible = false;
            await this.$nextTick();
            window.dispatchEvent(new Event('resize'));
        },

        scrollToFocusedElementIfNecessary(event: FocusEvent) {
            // When focusing an element, it might scroll into the viewport under a floating header or footer.
            // Let's move it to the center of the screen if it's obscured.
            const eventTarget = event.target;
            if (eventTarget instanceof Element && eventTarget.tagName !== 'A') {
                // Give the UI a bit to settle down.
                setTimeout(() => {
                    const bounds = eventTarget.getBoundingClientRect();
                    const centerX = bounds.left + bounds.width / 2;
                    const centerY = bounds.top + bounds.height / 2;
                    const topElementAtCenter = document.elementFromPoint(centerX, centerY);
                    const targetIsObscured = topElementAtCenter !== eventTarget && !eventTarget.contains(topElementAtCenter);
                    const obscuredByOverlay = topElementAtCenter?.classList.contains('overlay');
                    if (targetIsObscured && !obscuredByOverlay) {
                        try {
                            eventTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
                        } catch (error) {
                            // The element is probably not there anymore.
                        }
                    }
                });
            }
        },

        trackActionsPageLinkClick() {
            trackActionsPageEvent('Clicked Actions Page Mobile Nav Link');
        },
    }
});
</script>

<style scoped>
.mobile-layout {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.title-bar {
    background: linear-gradient(white env(safe-area-inset-top), var(--color-secondary) env(safe-area-inset-top)) !important;
    flex: 0;
    padding-top: env(safe-area-inset-top);
    position: sticky;
    top: 0;
    z-index: 2;
}

.mobile-content-wrapper {
    flex: 1;
    z-index: 1;
}

.site-app-banner {
    z-index: 6;
}
.home-or-back {
    align-self: center;
}
:deep(.v-toolbar__content > .v-btn) {
    margin-left: var(--spacing-0) !important;
}
.v-list :deep(.v-btn) {
    justify-content: left;
}
.v-toolbar__title {
    width: 100%;
    font-weight: 600;
    font-size: 1.4rem;
}

.bottom-navigation {
    background: var(--color-white);
    bottom: 0;
    display: flex;
    /* flex: 0 0 0%; */
    font-size: 12px;
    font-weight: 600;
    overflow-x: auto;
    padding-bottom: env(safe-area-inset-bottom);
    position: sticky;
    z-index: 2;
}

.bottom-navigation a,
.bottom-navigation button {
    color: inherit;
    flex: 1 0 auto;
    text-decoration: none;
}

.nav-button {
    border-radius: var(--spacing-2);
    color: var(--color-dark);
    padding: 8px 0.5ch;
    min-width: 4em;
    text-align: center;
}

:active > .nav-button {
    background: #8881;
}

.nav-icon {
  color: inherit;
}

.nav-button:is(.router-link-active *) {
  color: var(--color-primary);
}

.copyright {
    bottom: 0;
    left: 0;
    opacity: 0.7;
    position: absolute;
}
</style>
