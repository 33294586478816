
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  color: var(--color-dark);
}

a {
  color: var(--color-primary-shade);
}

img {
  image-orientation: from-image;
  max-width: 100%;
}


/* Typography */
html {
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family: var(--type-sans);
  font-size: var(--type-base);
  line-height: 1.4;
}

h1, h2, h3, h4, h5 {
  font-family: var(--type-headline);
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
  overflow-wrap: break-word;
}

h3, h4, h5 {
  -webkit-font-smoothing: subpixel-antialiased;
}

p {
  font-weight: normal;
}


.t-title, h1 {
  font-size: var(--type-title);
  line-height: 1.1;
}
.t-subtitle, h2 {
  font-size: var(--type-subtitle);
  line-height: 1.1;
}
.t-sectiontitle, h3 {
  font-size: var(--type-base);
  line-height: 1.1;
}
.t-lead {
  font-size: var(--type-lead);
  line-height: 1.25;
}
.t-base {
  font-size: var(--type-base);
}
.t-small {
  font-size: var(--type-small);
}

.t-sans {
  font-family: var(--type-sans);
}

.t-serif {
  font-family: var(--type-serif);
}

.basic-table {
  border-collapse: collapse;
  width: 100%;
  tbody tr:nth-child(even) {
    background-color: rgba(0,0,0,0.05);
  }
  td,th {
    padding: 0.2em;
  }
  td {
    text-align: right;
  }
}

.web-layout {
  .mobile-only {
    display: none;
  }

  .columns {
    display: flex;
  }
  .columns:not(.gapless) {
    margin: 0 -1rem;
  }

  [class*="columns"] {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  [class*="columns"]:not(.gapless) > * {
    padding: 0 1rem;
  }

  .columns--2 > * {
      width: 50%;
  }
  .columns--3 > * {
      width: calc(100% / 3);
  }
  .columns--4 > * {
      width: calc(100% / 4);
  }

  @media screen and (max-width: 768px) {
      .columns {
          flex-direction: column;
      }
      .columns > * {
          width: 100%;
      }
  }

  .narrow-container {
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mid-container {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .large-container {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* The space between the fields - between hour and minute, the minute and
second, second and am/pm */
input[type=time]::-webkit-datetime-edit-text {
  /*padding: 19px 4px;*/
}

/* Hour */
input[type=time]::-webkit-datetime-edit-hour-field {
  padding-right: 5px;
}

/* Minute */
input[type=time]::-webkit-datetime-edit-minute-field {
  padding-left: 5px;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type=time]::-webkit-inner-spin-button {
  widows: 30px;
  height: 30px;
  margin-top: -10px;
}

/* A global tweak to Vuetify's time picker highlighting the number being edited: */

.v-picker--time .v-time-picker-title__time .v-picker__title__btn {
    border-radius: 3px;
}

.v-picker--time .v-time-picker-title__time .v-picker__title__btn:not(.v-picker__title__btn--active):hover {
    box-shadow: 0 0 0 2px #ffc3 inset;
}

.v-picker--time .v-time-picker-title__time .v-picker__title__btn--active {
    background: #ffc3;
    box-shadow: 0 0 0 1px #ffc1 inset;
}
