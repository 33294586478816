.v-application {
    font-family: unset;
}
.theme--light.v-tabs .v-tabs-bar {
    background-color: transparent;
}
.v-application a {
    color: var(--color-primary);
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0px !important;
    border: 1px solid rgba(0,0,0,0.42);
}
.v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
    left: -1px;
    width: calc(100% + 1px);
}
.theme--light.v-text-field--box:not(.v-input--is-focused) > .v-input__control > .v-input__slot:hover {
    background: var(--color-light);
}
.v-input:not(.v-input--selection-controls) .v-label {
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: visible !important;
}
.v-label--active {
    font-weight: bold;
}

.v-input__slot {
    font-size: var(--type-base);
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    font-weight: 500;
    color: var(--color-primary);
}
.v-tab.v-tab--active {
    color: var(--color-dark);
}
.v-tabs .v-tabs-slider-wrapper {
    color: var(--color-warning);
}
.v-dialog--fullscreen {
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-top);
}
.v-dialog.v-bottom-sheet {
    overflow-y: scroll;
}
.v-dialog.v-bottom-sheet .v-subheader {
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 1;
}
.v-card__title {
    word-break: break-word !important; /* Vuetify has this as `break-all`, which is very weird. */
}
