<template>
    <transition-expand :value="value" class="prompt-banner-root" @resize="$emit('resize', $event)">
        <div class="prompt" :data-mobile="$vuetify.breakpoint.xs">
            <div class="content">
                <div class="icon-container">
                    <v-icon class="icon" @click="$emit('icon-click')">{{ icon }}</v-icon>
                </div>

                <div class="message">
                    <div>
                        <slot />
                    </div>
                </div>

                <div v-if="$slots.actions" class="actions">
                    <slot name="actions" />
                </div>
            </div>
        </div>
    </transition-expand>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default Vue.extend({
    components: {
        TransitionExpand,
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },

        icon: {
            type: String,
            default: 'info'
        }
    },
});
</script>

<style lang="postcss" scoped>
.prompt-banner-root {
    --transition-expand-duration: 1s;
    background: var(--color-secondary); /* Blend in when it's collapsed (there might be a better place to set this). */
}

.prompt {
    background-color: var(--color-light-shade);
    box-shadow: 0 2px 4px #0003;
    border-top: 1px solid #8883;
    padding: var(--spacing-4) var(--spacing-3) var(--spacing-2);
}

.content {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-inline: auto;
    max-width: min(1440px, 93vw);
    width: max-content;
}

.icon-container {
    align-self: flex-start;
    background: var(--color-danger);
    border-radius: 10em;
    color: white;
    grid-column: 1;
    margin-inline-end: var(--spacing-2);
    padding: var(--spacing-2);
}

.icon {
    color: inherit !important;
}

.message {
    display: flex;
    align-items: center;
    grid-column: 2;
}

.message > :deep(:first-child) {
    margin-block-start: 0;
}

.message > :deep(:last-child) {
    margin-block-end: 0;
}

.actions {
    grid-area: 2 / 1 / 3 / 3;
    text-align: center;
}

[data-mobile] .actions {
    text-align: end;
}
</style>
