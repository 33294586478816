<template>
    <component :is="tag" :data-looks-like="looksLike" :data-small="small" v-bind="$attrs">
        <slot />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    inject: ['actualHeadingLevel', 'visualHeadingLevel'],

    props: {
        small: { type: Boolean, default: false },
    },

    computed: {
        tag(): `h${number}` {
            // @ts-ignore
            return `h${this.actualHeadingLevel}`;
        },

        looksLike(): `h${number}` {
            // @ts-ignore
            return `h${this.visualHeadingLevel}`;
        }
    },
});
</script>

<style>
    [data-looks-like] {
        --font-scale: 1;
        --font-size: 1em;
        font-family: var(--type-headline);
        font-size: calc(var(--font-size) * var(--font-scale));
        line-height: 1.2;
    }

    [data-looks-like][data-small] {
        --font-scale: 0.8;
    }

    [data-looks-like="h1"] {
        --font-size: 60px;
    }

    [data-looks-like="h2"] {
        --font-size: 36px;
    }

    [data-looks-like="h3"] {
        --font-size: 20px;
    }
</style>
