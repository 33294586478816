<template>
    <v-lazy
        :options="{ threshold: 0.25 }"
        min-height="24"
        transition="fade-transition"
    >
        <div v-if="imageOnly" :class="`avatar avatar--${size}`">
            <router-link v-if="link" :to="{ name: 'user.detail', params: { id: user.id }}" v-bind="avatarImgProps" />
            <div v-else v-bind="avatarImgProps" />
            <slot name="upload-image" />
        </div>
        <div v-else :class="`avatar avatar--${size}`">
            <router-link v-if="link" :to="{ name: 'user.detail', params: { id: user.id }}" v-bind="avatarImgProps" />
            <div v-else v-bind="avatarImgProps" />
            <div class="avatar__content ml-2">
                <router-link v-if="link" :to="{ name: 'user.detail', params: { id: user.id }}" class="avatar__name">{{ fullname }}</router-link>
                <div v-else class="avatar__name">{{ fullname }}</div>
                <div class="avatar__extra-content">
                    <slot />
                </div>
            </div>
        </div>
    </v-lazy>
</template>
<script lang="ts">
declare const require: Function;
import Vue from '@/vueTyped';
export default Vue.extend({
    name: 'UserAvatar',
    props: {
        imageOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'default',
        },
        link: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dimension() {
            switch (this.size) {
                case 'small':
                    return 48;
                case 'medium':
                    return 64;
                case 'large':
                    return 150;
                case 'extra-large':
                    return 260;
                default:
                    return 48;
            }
        },
        fullname(): string {
            return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
        },
        avatarImgProps(): any {
            return {
                class: 'avatar__img',
                title: this.fullname,
                // @ts-ignore
                style: `background-image: url(${this.user.avatar ? this.$options.filters.formatImage(this.user.avatar, this.dimension, this.dimension) : require('@/assets/images/default_profile_image.png')})`
            };
        }
    }
});
</script>

<style lang="postcss" scoped>
.avatar {
    display: flex;
    align-items: center;
    --avatar-dim: 48px;
}
.avatar--small {
    --avatar-dim: 24px;
    align-items: center;
}
.avatar--medium {
    --avatar-dim: 32px;
    align-items: center;
}
.avatar--large {
    --avatar-dim: 75px;
}
.avatar--extra-large {
    --avatar-dim: 130px;
}
.avatar__img {
    --internal-size-value: var(--avatar-size, var(--avatar-dim));
    width: auto;
    display: inline-block;
    min-width: 50px;
    border-radius: 50%;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.5);
    min-width: var(--internal-size-value);
    min-height: var(--internal-size-value);
    max-width: var(--internal-size-value);
    max-height: var(--internal-size-value);
}
.avatar__name {
    font-weight: bold;
    text-decoration: none;
}
.avatar__img:after {
    content: '';
    padding-top: 100%;
    display: block;
}

</style>
