<template>
    <component :is="tag" class="base-page-content">
        <slot />
    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default: 'div',
        },
    },
});
</script>

<style lang="postcss" scoped>
.base-page-content {
    margin: var(--spacing-8) auto;
    max-width: 1000px;
    width: 90vw;
}
</style>
