<template>
  <div class="form-control input mb-2">
    <v-text-field
      v-if="type === 'password'"
      validate-on-blur
      :type="show ? 'text' : 'password'"
      :placeholder="placeholder"
      autocomplete="off"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-for="(_, slot) of $slots" #[slot]><slot :name="slot" /></template>
      <template #append>
        <v-btn icon :aria-label="$t('account.togglePasswordVisibility')" @click="show = !show">
          <v-icon>{{ show ? 'visibility' : 'visibility_off' }}</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-text-field
      v-else
      validate-on-blur
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      @change.native="handleNativeInputChange"
    >
      <template v-for="(_, slot) of $slots" #[slot]><slot :name="slot" /></template>
      <template v-if="optional" #label>
        <div>
          {{ $attrs.label }} <small class="text-none">(optional)</small>
        </div>
      </template>
    </v-text-field>
  </div>
</template>
<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      // default to single space to force smaller label treatment
      default: ' ',
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false,
  }),
  methods: {
    handleNativeInputChange(event: InputEvent) {
      const input = event.target;

      if (!(input instanceof HTMLInputElement)) return;
      if (input.type !== 'number') return;
      if (!input.step) return;

      // On change, ensure there's a decimal point displayed if the step allows for one.

      const step = parseFloat(input.step);
      const decimalPlaces = (step.toString().split('.')[1] ?? '').length;
      const needsDecimal = decimalPlaces > 0 && !input.value.includes('.');

      if (needsDecimal) {
        setTimeout(() => {
          input.value = input.valueAsNumber.toFixed(decimalPlaces);
          this.$emit('input', input.value);
          this.$emit('change', input.value);
        });
      }
    },
  },
});
</script>
<style lang="postcss" scoped>
:deep(.v-text-field__slot) {
  /* This flexes weirdly when small. */
  min-width: 0;
}
</style>
