<template>
    <div>
        <template v-if="browserSupportsInputTypeDatetimeLocal">
            <input
                type="datetime-local"
                :value="value"
                :data-no-value="!value"
                :min="minMaxForInput.min"
                :max="minMaxForInput.max"
                :step="5 * 60"
                :placeholder="$props.placeholder ?? $t('defaultPlaceholder')"
                @input="$emit('input', $event.currentTarget.value)"
            >
        </template>

        <template v-else>
            <v-dialog
                v-if="currentlyOpenPicker"
                :value="true"
                width="290px"
                @input="confirm"
            >
                <component
                    :is="currentlyOpenPicker === 'date' ? 'v-date-picker' : 'v-time-picker'"
                    :value="currentlyOpenPicker === 'date' ? internalDate : internalTime"
                    scrollable
                    :min="actualMinMax.min[currentlyOpenPicker]"
                    :max="actualMinMax.max[currentlyOpenPicker]"
                    :allowed-minutes="minute => minute % 5 === 0"
                    full-width
                    @input="currentlyOpenPicker === 'date' ? internalDate = $event : internalTime = $event"
                >
                    <base-button v-if="value" text color="primary" @click="clear">Clear</base-button>
                    <v-spacer />
                    <base-button color="primary" @click="confirm">OK</base-button>
                    <v-spacer v-if="!value" />
                </component>
            </v-dialog>

            <div class="button-joiner">
                <base-button small outlined @click="currentlyOpenPicker = 'date'">
                    <span class="button-label">
                        <v-icon small aria-label="Edit the date" class="button-icon">calendar_today</v-icon>
                        {{ formattedDate || 'Date' }}
                    </span>
                </base-button>

                <base-button small outlined style="margin-left: -1px;" @click="currentlyOpenPicker = 'time'">
                    <span class="button-label">
                        <v-icon small aria-label="Edit the time" class="button-icon">schedule</v-icon>
                        {{ formattedTime || 'Time' }}
                    </span>
                </base-button>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import moment from 'moment';

// const BROWSER_SUPPORTS_INPUT_TYPE_DATETIME_LOCAL = (() => {
//     const input: HTMLInputElement = document.createElement('input');
//     input.type = 'datetime-local';
//     return input.type === 'datetime-local';
// }).call(null);

type MoreUsefulMinMax = {
    min: {
        date: string | null;
        time: string | null;
    };

    max: {
        date: string | null;
        time: string | null;
    };
};

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                defaultPlaceholder: 'Pick a date and time'
            },
            es: {
                defaultPlaceholder: 'Elija una fecha y hora'
            },
        }
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        defaultValue: {
            type: String,
            default: '',
        },

        min: {
            type: String,
            default: '',
        },

        max: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            currentlyOpenPicker: null as 'date' | 'time' | null,
            internalDate: '',
            internalTime: '',
        };
    },

    computed: {
        browserSupportsInputTypeDatetimeLocal(): boolean {
            return false;
            // return BROWSER_SUPPORTS_INPUT_TYPE_DATETIME_LOCAL;
            // This may be possible once style without value is improved. On iOS it's just a gray box.
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
        },

        formattedDate(): string | null {
            return this.value ? moment(this.value).format('LL') : null;
        },

        formattedTime(): string | null {
            return this.value ? moment(this.value).format('h:mm A') : null;
        },

        actualMinMax(): MoreUsefulMinMax {
            const result: MoreUsefulMinMax = {
                min: { date: null , time: null },
                max: { date: null , time: null },
            };

            const currentDate = moment().format('YYYY-MM-DD');

            if (this.min) {
                const minMoment = moment(this.min === 'now' ? new Date() : this.min);
                result.min.date = minMoment.format('YYYY-MM-DD');

                if (result.min.date === (this.internalDate || currentDate)) {
                    result.min.time = minMoment.format('HH:mm');
                } else {
                    result.min.time = '00:00';
                }
            }

            if (this.max) {
                const maxMoment = moment(this.max === 'now' ? new Date() : this.max);
                result.max.date = maxMoment.format('YYYY-MM-DD');

                if (result.max.date === (this.internalDate || currentDate)) {
                    result.max.time = maxMoment.format('HH:mm');
                } else {
                    result.max.time = '23:59';
                }
            }

            return result;
        },

        minMaxForInput(): { min: string, max: string } {
            return {
                min: this.min ? `${this.actualMinMax.min.date}T${this.actualMinMax.min.time}` : '',
                max: this.max ? `${this.actualMinMax.max.date}T${this.actualMinMax.max.time}` : '',
            };
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.setInternalValue(value);
                } else if (this.defaultValue) {
                    this.setInternalValue(this.defaultValue);
                }
            },
        },

        defaultValue: {
            immediate: true,
            handler(defaultValue) {
                if (!this.value) {
                    this.setInternalValue(defaultValue);
                }
            },
        },
    },

    methods: {
        setInternalValue(externalValue: string) {
            const valueDate = moment(externalValue === 'now' ? new Date() : externalValue);

            if (valueDate.isValid()) {
                this.internalDate = valueDate.format('YYYY-MM-DD');
                this.internalTime = valueDate.format('HH:mm');
            } else {
                this.internalDate = '';
                this.internalTime = '';
            }
        },

        clear() {
            this.$emit('input', '');
            this.currentlyOpenPicker = null;
        },

        confirm() {
            let internalValue = '';

            if (this.internalDate || this.internalTime) {
                const valueDate = this.internalDate || moment().format('YYYY-MM-DD'); // If no date is set, default to today.
                const valueTime = this.internalTime || '00:00'; // If no time is set, default to midnight.
                internalValue = `${valueDate}T${valueTime}`; // Explicitly not suffixed with "Z", so this is local time.
            }

            this.$emit('input', internalValue);

            this.currentlyOpenPicker = this.currentlyOpenPicker === 'date' ? 'time' : null;
        },
    },
});
</script>

<style lang="postcss" scoped>
/* input[type="datetime-local"] {
    all: revert;
    position: relative;
} */

/* input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-month-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-day-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-year-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-hour-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-minute-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-ampm-field,
input[type="datetime-local"][data-no-value]:not(:focus)::-webkit-datetime-edit-text {
    display: none;
} */

/* input[type="datetime-local"][data-no-value]:not(:focus)::after {
    content: attr(placeholder);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
} */

.button-joiner {
    display: flex;
}

.button-joiner > * {
    flex: 1 1 auto;
}

.button-joiner > :not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.button-joiner > :not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.button-label {
    font-size: var(--type-interface);
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
}

.button-icon {
    vertical-align: bottom;
}
</style>
