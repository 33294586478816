<template>
    <div>
        <v-select
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="internalValue"
            :label="label"
            :items="items"
            :return-object="returnObject"
            :item-text="itemText"
            :item-value="itemValue"
            :placeholder="placeholder"
            append-icon="expand_more"
            class="mb-3"
            v-bind="$attrs"
        >
            <template #item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ typeof item === 'string' ? item : item[itemText] }}
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="item[itemDescription]">
                            {{ item[itemDescription] }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>

        <v-bottom-sheet v-else v-model="selectOpen">
            <template #activator="{ attrs, on }">
                <div
                    tabindex="0"
                    class="mb-3"
                    v-bind="attrs"
                    v-on="on"
                    @keydown.enter="selectOpen = true"
                >
                    <div class="mobile-label">{{ label }}</div>
                    <div class="mobile-value" :data-placeholder="!value">
                        {{ mobileValueString || placeholder }}
                    </div>
                </div>
                <div v-if="$attrs.hint" class="mobile-hint">{{ $attrs.hint }}</div>
            </template>

            <v-list>
                <v-subheader>{{ label }}</v-subheader>

                <v-list-item
                    v-for="item in items"
                    :key="typeof item === 'string' ? item : item[itemValue]"
                    @click="internalValue = returnObject || typeof item === 'string' ? item : item[itemValue]; selectOpen = false;"
                >
                    <v-list-item-content>
                        <v-list-item-title :class="{ 'primary--text': item === value }">
                            <span class="mr-2">{{ typeof item !== 'string' ? item[itemText] : item }}</span>
                            <v-icon v-if="item === value">check</v-icon>
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="item[itemDescription]">
                            {{ item[itemDescription] }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    inheritAttrs: false,

    props: {
        value: {
            type: [String, Object],
            default: null,
        },

        label: {
            required: true,
            type: String,
        },

        placeholder: {
            type: String,
            default: '--',
        },

        items: {
            required: true,
            type: Array,
        },

        returnObject: {
            type: Boolean,
            default: false,
        },

        itemText: {
            type: String,
            default: 'text',
        },

        itemDescription: {
            type: String,
            default: 'description',
        },

        itemValue: {
            type: String,
            default: 'value',
        },
    },

    data() {
        return {
            selectOpen: false,
        };
    },

    computed: {
        internalValue: {
            get(): any {
                return this.value;
            },

            set(value: any) {
                this.$emit('input', value);
                this.$emit('change', value);
            },
        },

        mobileValueString(): string {
            if (typeof this.items[0] === 'string') {
                return this.value;
            } else if (this.returnObject) {
                return this.value?.[this.itemText];
            } else {
                const valueItem = this.items.find((item: any) => item[this.itemValue] === this.value);
                return (valueItem as any)?.[this.itemText];
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
/* Mimic an active Vuetify input's placeholder/label text. */
.mobile-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: calc(16px * 0.75);
    font-weight: bold;
    text-transform: uppercase;
}

.mobile-value {
    font-size: var(--type-base);
    color: var(--color-dark);
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.mobile-value[data-placeholder] {
    color: var(--color-medium);
}

/* Mimic Vuetify's input hints */
.mobile-hint {
    color: rgba(0, 0, 0, 0.6);
    font-size: var(--type-small);
    line-height: 1.3;
}
</style>
