<template>
    <div class="footer-links-root">
        <ul>
            <li>
                <router-link :to="{ name: 'cms_content_page', params: { collection: 'privacy' } }">{{ $t('nav.privacy') }}</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'cms_content_page', params: { collection: 'terms' } }">{{ $t('nav.terms') }}</router-link>
            </li>
            <li>
                <router-link to="/about/contact">{{ $t('nav.contact') }}</router-link>
            </li>
            <li>
                <a href="https://iseechange.notion.site/Jobs-at-ISeeChange-120b4c78301442169fdd104d814b1a4e" target="_blank">{{ $t('nav.careers') }}</a>
            </li>
            <li v-if="$store.getters.coveredByMiamiA11y">
                <router-link to="/miami-accessibility">{{ $t('nav.accessibility') }}</router-link>
            </li>
        </ul>

        <div>
            <ul>
                <li>
                    <a href="https://twitter.com/iseechange" target="_blank">
                        <v-icon style="color: inherit;">$twitter</v-icon>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/youseechange/" target="_blank">
                        <v-icon style="color: inherit;">$instagram</v-icon>
                    </a>
                </li>
                <li>
                    <a href="https://facebook.com/iseechange" target="_blank">
                        <v-icon style="color: inherit;">$facebook</v-icon>
                    </a>
                </li>
            </ul>

            <release-info class="copyright">&copy; {{ new Date().getFullYear() }} ISeeChange</release-info>
            {{ ' ' }}
            <experiments-menu />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import ReleaseInfo from '@/components/ReleaseInfo.vue';
import ExperimentsMenu from './ExperimentsMenu.vue';

export default Vue.extend({
    components: {
        ExperimentsMenu,
        ReleaseInfo,
    },
});
</script>

<style lang="postcss" scoped>
.footer-links-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -1ch; /* Restore links' original positions. */
}

.footer-links-root ul {
    display: inline;
    padding: 0;
    vertical-align: middle;
}

.footer-links-root li {
    display: inline;
}

.footer-links-root a {
    color: inherit;
    display: inline-block;
    padding: 1ch;
    text-decoration: none;
}

.footer-links-root .copyright {
    margin-inline: 2em 1ch;
}
</style>
