<!--
This is a pretty weird way of doing this,
but it makes it a bit easier to manage custom icons with multiple colors based on the state of the parent link.
When changing these make sure you replace dark and light colors with `--stroke` and `--fill`.
-->

<template>
    <span v-if="which === 'sightings'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path d="M21.9999 15.9296C21.9999 17.9414 20.3691 19.5722 18.3574 19.5722C16.3457 19.5722 14.7148 17.9414 14.7148 15.9296C14.7148 13.9179 16.3457 12.2871 18.3574 12.2871C20.3691 12.2871 21.9999 13.9179 21.9999 15.9296Z" fill="var(--fill)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3574 13.2871C16.8979 13.2871 15.7148 14.4702 15.7148 15.9296C15.7148 17.3891 16.8979 18.5722 18.3574 18.5722C19.8168 18.5722 20.9999 17.3891 20.9999 15.9296C20.9999 14.4702 19.8168 13.2871 18.3574 13.2871ZM13.7148 15.9296C13.7148 13.3656 15.7934 11.2871 18.3574 11.2871C20.9214 11.2871 22.9999 13.3656 22.9999 15.9296C22.9999 18.4936 20.9214 20.5722 18.3574 20.5722C15.7934 20.5722 13.7148 18.4936 13.7148 15.9296Z" fill="var(--stroke)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.93147 15.2226C9.322 14.8321 9.95516 14.8321 10.3457 15.2226C11.2591 16.136 12.7401 16.136 13.6535 15.2226C14.044 14.8321 14.6772 14.8321 15.0677 15.2226C15.4583 15.6131 15.4583 16.2463 15.0677 16.6368C13.3732 18.3313 10.626 18.3313 8.93147 16.6368C8.54095 16.2463 8.54095 15.6131 8.93147 15.2226Z" fill="var(--stroke)" />
            <path d="M9.28897 15.9296C9.28897 17.9414 7.65815 19.5722 5.64644 19.5722C3.63472 19.5722 2.00391 17.9414 2.00391 15.9296C2.00391 13.9179 3.63472 12.2871 5.64644 12.2871C7.65815 12.2871 9.28897 13.9179 9.28897 15.9296Z" fill="var(--fill)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64644 13.2871C4.18701 13.2871 3.00391 14.4702 3.00391 15.9296C3.00391 17.3891 4.18701 18.5722 5.64644 18.5722C7.10587 18.5722 8.28897 17.3891 8.28897 15.9296C8.28897 14.4702 7.10587 13.2871 5.64644 13.2871ZM1.00391 15.9296C1.00391 13.3656 3.08244 11.2871 5.64644 11.2871C8.21044 11.2871 10.289 13.3656 10.289 15.9296C10.289 18.4936 8.21044 20.5722 5.64644 20.5722C3.08244 20.5722 1.00391 18.4936 1.00391 15.9296Z" fill="var(--stroke)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1538 5.41406C7.32031 5.41406 6.5742 5.93097 6.28133 6.71132L3.10716 15.1689C2.9131 15.686 2.33662 15.9479 1.81955 15.7538C1.30248 15.5598 1.04063 14.9833 1.23469 14.4662L4.40886 6.00857C4.99459 4.44788 6.48681 3.41406 8.1538 3.41406H8.77009C10.0773 3.41406 11.2634 4.04737 11.9991 5.05274C12.7349 4.04737 13.9209 3.41406 15.2282 3.41406H15.8445C17.5115 3.41406 19.0037 4.44788 19.5894 6.00858L22.7636 14.4662C22.9576 14.9833 22.6958 15.5598 22.1787 15.7538C21.6617 15.9479 21.0852 15.686 20.8911 15.1689L17.7169 6.71132C17.4241 5.93097 16.678 5.41406 15.8445 5.41406H15.2282C14.2983 5.41406 13.4911 6.05492 13.2803 6.96057L12.9731 8.28005C12.8677 8.73287 12.4641 9.0533 11.9991 9.0533C11.5342 9.0533 11.1306 8.73287 11.0252 8.28005L10.718 6.96057C10.5071 6.05492 9.69995 5.41406 8.77009 5.41406H8.1538Z" fill="var(--stroke)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.8418C12.5523 5.8418 13 6.28951 13 6.8418V9.8418C13 10.3941 12.5523 10.8418 12 10.8418C11.4477 10.8418 11 10.3941 11 9.8418V6.8418C11 6.28951 11.4477 5.8418 12 5.8418Z" fill="var(--stroke)" />
        </svg>
    </span>

    <span v-else-if="which === 'projects'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5991 8.40071C19.6865 12.4881 21.3885 17.4129 19.4008 19.4007C17.413 21.3884 12.4882 19.6864 8.4008 15.599C4.31345 11.5117 2.6114 6.58684 4.59917 4.59907C6.58693 2.61131 11.5118 4.31336 15.5991 8.40071Z" fill="var(--fill)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.16687 8.98507C5.79539 10.8037 7.14744 12.9316 9.10782 14.8919C11.0682 16.8523 13.1961 18.2044 15.0147 18.8329C16.9047 19.4861 18.1222 19.265 18.6936 18.6936C19.265 18.1222 19.4861 16.9047 18.8329 15.0147C18.2044 13.1961 16.8523 11.0682 14.8919 9.10782C12.9316 7.14744 10.8037 5.79539 8.98507 5.16687C7.09501 4.51366 5.8776 4.73476 5.30618 5.30618C4.73476 5.8776 4.51366 7.09501 5.16687 8.98507ZM3.27658 9.63836C2.55387 7.54722 2.47562 5.30831 3.89197 3.89197C5.30832 2.47562 7.54722 2.55387 9.63836 3.27658C11.801 4.02398 14.1792 5.56663 16.3062 7.6936C18.4331 9.82058 19.9758 12.1988 20.7232 14.3614C21.4459 16.4525 21.5241 18.6914 20.1078 20.1078C18.6914 21.5241 16.4525 21.4459 14.3614 20.7232C12.1988 19.9758 9.82058 18.4331 7.6936 16.3062C5.56663 14.1792 4.02398 11.801 3.27658 9.63836Z" fill="var(--stroke)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0149 5.16711C13.1963 5.79563 11.0684 7.14769 9.10806 9.10806C7.14769 11.0684 5.79563 13.1963 5.16712 15.0149C4.5139 16.905 4.73501 18.1224 5.30643 18.6938C5.87784 19.2652 7.09525 19.4863 8.98532 18.8331C10.8039 18.2046 12.9318 16.8526 14.8922 14.8922C16.8526 12.9318 18.2046 10.8039 18.8331 8.98531C19.4863 7.09525 19.2652 5.87784 18.6938 5.30643C18.1224 4.73501 16.905 4.5139 15.0149 5.16711ZM14.3616 3.27682C16.4528 2.55411 18.6917 2.47587 20.108 3.89221C21.5244 5.30856 21.4461 7.54746 20.7234 9.63861C19.976 11.8012 18.4334 14.1794 16.3064 16.3064C14.1794 18.4334 11.8012 19.976 9.63861 20.7234C7.54746 21.4461 5.30856 21.5244 3.89221 20.108C2.47587 18.6917 2.55411 16.4528 3.27682 14.3616C4.02423 12.199 5.56687 9.82082 7.69385 7.69385C9.82082 5.56687 12.199 4.02423 14.3616 3.27682Z" fill="var(--stroke)" />
        </svg>
    </span>

    <span v-else-if="which === 'add-sighting'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#F8FCFF" />
            <path d="M11 13V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H13V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V11H8C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13H11ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="var(--stroke)" />
        </svg>
    </span>

    <span v-else-if="which === 'actions'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3341 2.67467C11.765 2.52251 12.235 2.52251 12.6659 2.67467L18.6659 4.79336C19.4654 5.07567 20 5.83139 20 6.67924V10.9155C20 13.3307 19.2817 15.5263 17.8544 17.4721C16.4981 19.321 14.7471 20.6222 12.6181 21.3503C12.2175 21.4873 11.7825 21.4873 11.3819 21.3503C9.25289 20.6222 7.50188 19.321 6.1456 17.4721C4.71832 15.5263 4 13.3307 4 10.9155V6.67924C4 5.83139 4.53459 5.07567 5.33407 4.79336L11.3341 2.67467ZM12 4.56055L6 6.67924L6 10.9155C6 12.9058 6.58296 14.6869 7.75825 16.2891C8.87337 17.8093 10.2818 18.854 12 19.4479C13.7182 18.854 15.1266 17.8093 16.2418 16.2891C17.417 14.6869 18 12.9058 18 10.9155V6.67924L12 4.56055Z" fill="var(--stroke)" />
            <path d="M12 4.56055L6 6.67924V10.9155C6 12.9058 6.58296 14.6869 7.75825 16.2891C8.87337 17.8093 10.2818 18.854 12 19.4479C13.7182 18.854 15.1266 17.8093 16.2418 16.2891C17.417 14.6869 18 12.9058 18 10.9155V6.67924L12 4.56055Z" fill="var(--fill)" />
        </svg>
    </span>

    <span v-else-if="which === 'map'">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4144_1925" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4144_1925)">
                <path d="M12.5 12C13.05 12 13.5208 11.8042 13.9125 11.4125C14.3042 11.0208 14.5 10.55 14.5 10C14.5 9.45 14.3042 8.97917 13.9125 8.5875C13.5208 8.19583 13.05 8 12.5 8C11.95 8 11.4792 8.19583 11.0875 8.5875C10.6958 8.97917 10.5 9.45 10.5 10C10.5 10.55 10.6958 11.0208 11.0875 11.4125C11.4792 11.8042 11.95 12 12.5 12ZM12.5 19.35C14.5333 17.4833 16.0417 15.7875 17.025 14.2625C18.0083 12.7375 18.5 11.3833 18.5 10.2C18.5 8.38333 17.9208 6.89583 16.7625 5.7375C15.6042 4.57917 14.1833 4 12.5 4C10.8167 4 9.39583 4.57917 8.2375 5.7375C7.07917 6.89583 6.5 8.38333 6.5 10.2C6.5 11.3833 6.99167 12.7375 7.975 14.2625C8.95833 15.7875 10.4667 17.4833 12.5 19.35ZM12.5 22C9.81667 19.7167 7.8125 17.5958 6.4875 15.6375C5.1625 13.6792 4.5 11.8667 4.5 10.2C4.5 7.7 5.30417 5.70833 6.9125 4.225C8.52083 2.74167 10.3833 2 12.5 2C14.6167 2 16.4792 2.74167 18.0875 4.225C19.6958 5.70833 20.5 7.7 20.5 10.2C20.5 11.8667 19.8375 13.6792 18.5125 15.6375C17.1875 17.5958 15.1833 19.7167 12.5 22Z" fill="var(--stroke)" />
            </g>
        </svg>
    </span>

    <span v-else-if="which === 'people'">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4142_3274" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
               <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4142_3274)">
              <path d="M1.5 20V17.2C1.5 16.6333 1.64583 16.1125 1.9375 15.6375C2.22917 15.1625 2.61667 14.8 3.1 14.55C4.13333 14.0333 5.18333 13.6458 6.25 13.3875C7.31667 13.1292 8.4 13 9.5 13C10.6 13 11.6833 13.1292 12.75 13.3875C13.8167 13.6458 14.8667 14.0333 15.9 14.55C16.3833 14.8 16.7708 15.1625 17.0625 15.6375C17.3542 16.1125 17.5 16.6333 17.5 17.2V20H1.5ZM19.5 20V17C19.5 16.2667 19.2958 15.5625 18.8875 14.8875C18.4792 14.2125 17.9 13.6333 17.15 13.15C18 13.25 18.8 13.4208 19.55 13.6625C20.3 13.9042 21 14.2 21.65 14.55C22.25 14.8833 22.7083 15.2542 23.025 15.6625C23.3417 16.0708 23.5 16.5167 23.5 17V20H19.5ZM9.5 12C8.4 12 7.45833 11.6083 6.675 10.825C5.89167 10.0417 5.5 9.1 5.5 8C5.5 6.9 5.89167 5.95833 6.675 5.175C7.45833 4.39167 8.4 4 9.5 4C10.6 4 11.5417 4.39167 12.325 5.175C13.1083 5.95833 13.5 6.9 13.5 8C13.5 9.1 13.1083 10.0417 12.325 10.825C11.5417 11.6083 10.6 12 9.5 12ZM19.5 8C19.5 9.1 19.1083 10.0417 18.325 10.825C17.5417 11.6083 16.6 12 15.5 12C15.3167 12 15.0833 11.9792 14.8 11.9375C14.5167 11.8958 14.2833 11.85 14.1 11.8C14.55 11.2667 14.8958 10.675 15.1375 10.025C15.3792 9.375 15.5 8.7 15.5 8C15.5 7.3 15.3792 6.625 15.1375 5.975C14.8958 5.325 14.55 4.73333 14.1 4.2C14.3333 4.11667 14.5667 4.0625 14.8 4.0375C15.0333 4.0125 15.2667 4 15.5 4C16.6 4 17.5417 4.39167 18.325 5.175C19.1083 5.95833 19.5 6.9 19.5 8ZM3.5 18H15.5V17.2C15.5 17.0167 15.4542 16.85 15.3625 16.7C15.2708 16.55 15.15 16.4333 15 16.35C14.1 15.9 13.1917 15.5625 12.275 15.3375C11.3583 15.1125 10.4333 15 9.5 15C8.56667 15 7.64167 15.1125 6.725 15.3375C5.80833 15.5625 4.9 15.9 4 16.35C3.85 16.4333 3.72917 16.55 3.6375 16.7C3.54583 16.85 3.5 17.0167 3.5 17.2V18ZM9.5 10C10.05 10 10.5208 9.80417 10.9125 9.4125C11.3042 9.02083 11.5 8.55 11.5 8C11.5 7.45 11.3042 6.97917 10.9125 6.5875C10.5208 6.19583 10.05 6 9.5 6C8.95 6 8.47917 6.19583 8.0875 6.5875C7.69583 6.97917 7.5 7.45 7.5 8C7.5 8.55 7.69583 9.02083 8.0875 9.4125C8.47917 9.80417 8.95 10 9.5 10Z" fill="var(--stroke)" />
            </g>
        </svg>
    </span>

    <span v-else-if="which === 'more'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20C11.45 20 10.9792 19.8042 10.5875 19.4125C10.1958 19.0208 10 18.55 10 18C10 17.45 10.1958 16.9792 10.5875 16.5875C10.9792 16.1958 11.45 16 12 16C12.55 16 13.0208 16.1958 13.4125 16.5875C13.8042 16.9792 14 17.45 14 18C14 18.55 13.8042 19.0208 13.4125 19.4125C13.0208 19.8042 12.55 20 12 20ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM12 8C11.45 8 10.9792 7.80417 10.5875 7.4125C10.1958 7.02083 10 6.55 10 6C10 5.45 10.1958 4.97917 10.5875 4.5875C10.9792 4.19583 11.45 4 12 4C12.55 4 13.0208 4.19583 13.4125 4.5875C13.8042 4.97917 14 5.45 14 6C14 6.55 13.8042 7.02083 13.4125 7.4125C13.0208 7.80417 12.55 8 12 8Z" fill="var(--stroke)" />
        </svg>
    </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        which: { type: String, default: '' },
    },
});
</script>

<style scoped>
span {
    --fill: transparent;
    --stroke: currentColor;
    display: contents;
}

span:is(.router-link-active *) {
    --fill: #D5EAFE;
}
</style>
