<template>
    <div class="form-control input mb-2">
      <v-textarea
        validate-on-blur
        :placeholder="$attrs.placeholder || ' '"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-for="(_, slot) of $slots" #[slot]><slot :name="slot" /></template>
      </v-textarea>
    </div>
</template>
<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
  name: 'BaseTextarea',
});
</script>
<style lang="postcss" scoped>
:deep(.v-text-field--filled > .v-input__control > .v-input__slot) {
  background: var(--color-white) !important;
}
</style>
