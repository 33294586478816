<template>
    <div class="content-root" :data-colder="colder">
        <h2>
            <deviation-description :analysis="analysis" />
        </h2>

        <div class="context mb-8">
            <span v-if="location" class="me-2">
                <v-icon class="icon">{{ locationIcon }}</v-icon>
                {{ analysisLocation }}
            </span>

            <span>
                <v-icon class="icon">device_thermostat</v-icon>
                {{ analysis.current }}°
            </span>
        </div>

        <div class="body">
            <h3>
                Past Vs
                <strong>Present</strong>
            </h3>

            <v-row align="end" justify="center">
                <v-col v-if="analysis.highDeviation >= 1" cols="auto" class="graph-container">
                    <change-graphs :expected="analysis.highExpectation" :actual="analysis.high" :style="rankStyles.high">
                        <div>High</div>
                        <div>{{ analysis.highTime.toISOString() | formatDate('h:mm A') }}</div>
                    </change-graphs>
                </v-col>

                <v-col v-if="analysis.lowDeviation >= 1" cols="auto" class="graph-container">
                    <change-graphs :expected="analysis.lowExpectation" :actual="analysis.low" :style="rankStyles.low">
                        <div>Low</div>
                        <div>{{ analysis.lowTime.toISOString() | formatDate('h:mm A') }}</div>
                    </change-graphs>
                </v-col>
            </v-row>

            <div class="mt-5 mb-8">
                <base-button faux-link @click="showingDataDetails = !showingDataDetails">
                    Learn about the data<!--
                    --><template v-if="!analysis.highIsGaussian || !analysis.lowIsGaussian">*</template>
                </base-button>
            </div>

            <transition-expand v-model="showingDataDetails" class="details">
                <markdown-output :value="$t('dataExplanation', { localDistance: $t(`localDistance.${units}`), weatherStationsDistance: $t(`weatherStationsDistance.${units}`) })" trusted />
            </transition-expand>
        </div>

        <template v-if="!noCta">
            <p class="prompts mt-5 mb-0">
                How does it feel?<br>
                How are you adjusting?
            </p>

            <action-footer>
                <base-button :to="{ name: 'posts.create' }" color="primary" @click="$emit('click-cta')">Share with the community</base-button>
            </action-footer>
        </template>

        <p>
            <small>
                Weather Data Provided by <a href="https://www.visualcrossing.com/" target="_blank">Visual Crossing</a>
                and <a href="https://www.aerisweather.com/" target="_blank">Aeris Weather</a>
            </small>
        </p>
    </div>
</template>

<script lang="ts">
import HistoricalWeatherPromptInjectedVue from './InjectedVue';
import ActionFooter from '../ActionFooter.vue';
import ChangeGraphs from './ChangeGraphs.vue';
import DeviationDescription from './DeviationDescription.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import TransitionExpand from '../TransitionExpand.vue';
import { trackHistoricalWeatherLearnMoreOpen } from '@/tracking';
import { parseAddressComponentsFromMBobj } from '@/util.mapbox';
import { AddressComponents, MBAddressObj } from '@/types';
import { unindent } from '@/util.app';

export default HistoricalWeatherPromptInjectedVue.extend({
    i18n: {
        messages: {
            en: {
                dataExplanation: unindent(`
                    We show you this prompt when the expected high and/or low temperature is different from the majority of the average daily high and low temperatures for this week over the last 30 years. We do this by analyzing the distribution of the historical data. First we look to see if the last 30 years creates a standard bell curve or [normal or gaussian distribution](^https://statisticsbyjim.com/basics/normal-distribution/). If it does, the data is symmetrical around the average of the dataset and the data closest to the top of the curve has a higher probability of recurring.

                    We use the following logic to notify you and differentiate how outside of the average today’s temps are from the last 30 years:
                    * Less than 1 standard deviation away (or is within 68% of the data points closest to the average), we don't notify you
                    * Between 1-2 standard deviations from average - we tell you today's weather is **"higher"** or **"lower"**
                    * Between 2-3 standard deviations from average - we tell you today's weather is **"much higher"** or **"much lower"**
                    * 3 or more standard deviations from average - we tell you today's weather is **"extremely high"** or **"extremely low"**

                    Sometimes, the historical weather data does not have a neat bell curve. This doesn’t mean the temps noted are normal, but, unfortunately, we can't tell you how unusual the temp is compared to the last 30 years. Instead, we inform you that the data **"could be abnormal\\*"** when it is at least one standard deviation away because record breaking temps can still exist in these scenarios and we could use your help documenting them!

                    We retrieved data within {localDistance} from your home location for today’s high and low temperature. These temps come from AerisWeather, which uses a blend of stations, satellites, and global and regional models.

                    We then compared the difference of today’s high and low with the last 30 years of average daily high and low temperatures for this week. This data comes from Visual Crossing which uses weather stations within {weatherStationsDistance} (this distance is common for historical weather records.)

                    Full [documentation for Aeris Weather can be found here](^https://www.aerisweather.com/support/docs/) and [for Visual Crossing here](^https://www.visualcrossing.com/resources/documentation/).
                    Please feel free to reach out to us at inquiry@iseechange.org with any additional questions about methodology.
                `),

                localDistance: {
                    metric: '1-15 km',
                    us: '0.6-9.3 mi',
                },

                weatherStationsDistance: {
                    metric: '50 km',
                    us: '31 mi',
                },
            },
        },
    },
    components: {
        ActionFooter,
        ChangeGraphs,
        DeviationDescription,
        MarkdownOutput,
        TransitionExpand,
    },

    props: {
        locationIcon: {
            type: String,
            default: 'location_on',
        },

        location: {
            type: Object as () => MBAddressObj | AddressComponents,
            default: null,
        },

        analysis: {
            type: Object,
            required: true,
        },

        noCta: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showingDataDetails: false,
        };
    },

    computed: {
        analysisLocation(): string | null {
            if (!this.location) {
                return null;
            }
            // Shifting types around here to support both MBAddressObj and AddressComponents
            // Kinda sloppy, sorry!
            let addressComponents: AddressComponents = this.location;
            if ((addressComponents as MBAddressObj).id) {
                addressComponents = parseAddressComponentsFromMBobj(addressComponents as MBAddressObj);
            }
            return [
                addressComponents?.city ?? addressComponents?.place,
                addressComponents?.country,
            ].filter(Boolean).join(', ');
        },

        units(): 'us' | 'metric' {
            const system = this.$store.state.account.currentUser?.userSettings?.preferredSystemOfMeasure ?? 'IMPERIAL';
            return system === 'METRIC' ? 'metric' : 'us';
        },

        rankStyles(): any {
            // TODO: Maybe we round the data in the analysis? This is messy.
            const allNumbers = [this.analysis.highExpectation, this.analysis.high, this.analysis.lowExpectation, this.analysis.low];
            const roundNumbers = allNumbers.map(Math.round);
            const uniqueNumbers = [...new Set(roundNumbers)];
            const sortedNumbers = uniqueNumbers.sort((a, b) => b - a).reverse();

            const highDiff = Math.round(Math.abs(this.analysis.highExpectation - this.analysis.high));
            const lowDiff = Math.round(Math.abs(this.analysis.lowExpectation - this.analysis.low));

            return {
                high: {
                    '--expected-multiplier': sortedNumbers.indexOf(Math.round(this.analysis.highExpectation)) + 1,
                    '--actual-multiplier': sortedNumbers.indexOf(Math.round(this.analysis.high)) + 1,
                    '--diff-multiplier': highDiff > lowDiff ? 2 : 1,
                },

                low: {
                    '--expected-multiplier': sortedNumbers.indexOf(Math.round(this.analysis.lowExpectation)) + 1,
                    '--actual-multiplier': sortedNumbers.indexOf(Math.round(this.analysis.low)) + 1,
                    '--diff-multiplier': highDiff < lowDiff ? 2 : 1,
                },
            };
        },

        colder(): boolean {
            const highDiff = this.analysis.high - this.analysis.highExpectation;
            const lowDiff = this.analysis.low - this.analysis.lowExpectation;
            const [larger] = [highDiff, lowDiff].sort((a, b) => Math.abs(b) - Math.abs(a));
            return larger < 0;
        },
    },

    watch: {
        showingDataDetails(showingDataDetails) {
            if (showingDataDetails) {
                trackHistoricalWeatherLearnMoreOpen(this.analysis);
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.content-root {
    background: white;
    color: black;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    font-size: var(--type-base);
    text-align: center;
}

h2 {
    background: linear-gradient(160deg, #974740, #E0695E);
    color: white;
    font-family: var(--type-sans);
    font-size: var(--type-lead);
    margin: 0;
    padding: var(--spacing-8);
    position: sticky;
    top: 0;
    z-index: 1;
}

[data-colder] h2 {
    background: linear-gradient(160deg, #2E384A, #44ACAD);
}

.context {
    background: var(--color-light);
    color: var(--color-dark);
    padding: var(--spacing-2) var(--spacing-4);
}

.context .icon {
    color: inherit;
}

.body {
    flex-grow: 1;
    margin-inline: var(--spacing-8);
}

h3 {
    color: var(--color-medium-shade);
    font-family: var(--type-sans);
    font-size: var(--type-lead);
}

h3 strong {
    color: var(--color-secondary);
    font-weight: inherit;
}

.graph-container {
    margin-inline: var(--spacing-4);
}

.details {
    font-size: var(--type-interface);
    text-align: start;
}

.prompts {
    font-weight: bold;
}
</style>
