<template>
    <heading-level class="web-layout" :data-main-nav-hidden="hideMainNav">
        <v-app-bar v-if="!hideMainNav" tag="nav" color="secondary" dark :elevation="0" height="96" fixed data-test-id="Main nav">
            <v-toolbar-title style="flex-shrink: 0;">
                <heading style="display: contents;">
                    <router-link to="/" class="pl-6 pr-3">
                        <primary-logo
                            :key="$vuetify.breakpoint.mdAndDown ? 'This `key` works around Safari bug' : 'Nice'"
                            class="logo"
                            :height="48"
                            :icon="$vuetify.breakpoint.mdAndDown"
                            style="color: white; display: inline-block;"
                        />
                    </router-link>
                </heading>
            </v-toolbar-title>

            <base-button text class="d-sr-only-focusable" @click="$el.querySelector('#main-content-focus-point').focus()">{{ $t('nav.skipToMain') }}</base-button>

            <v-spacer />

            <v-toolbar-items class="toolbar" style="--avatar-size: 48px;">
                <base-button v-if="hasClientGroup" :to="{ name: 'client-home' }" text>
                    {{ $t('nav.clients') }}
                </base-button>

                <base-button v-if="!hasClientGroup" to="/getting-started" text>
                    {{ $t('nav.gettingStarted') }}
                </base-button>

                <!-- <base-button :to="{ name: 'investigations' }" text>
                    {{ $t('routes.investigations') }}
                </base-button> -->

                <base-button :to="{ name: 'posts' }" text>
                    {{ $t('routes.posts') }}
                </base-button>

                <base-button :to="{ name: 'projects-index' }" text>
                    {{ $t('routes.projects') }}
                </base-button>

                <base-button :to="{ name: 'stories' }" text>
                    {{ $t('routes.stories') }}
                </base-button>

                <base-button href="https://partners.iseechange.com/about-us" text>
                    {{ $t('nav.about') }}
                </base-button>

                <a href="https://partners.iseechange.com/" class="button-placer">
                    <base-button tag="span" color="var(--color-blue-ocean)" style="height: var(--avatar-size);">
                        <span class="toolbar-button-label">{{ $t('nav.partnerWithUs') }}</span>
                    </base-button>
                </a>

                <span v-if="!currentUser" class="button-placer" :style="{ opacity: !userChecked ? 0 : undefined }">
                    <auth-button color="white" style="color: var(--color-blue-ocean); height: var(--avatar-size);">
                        <span class="toolbar-button-label">{{ $t('actions.login') }}</span>
                    </auth-button>
                </span>

                <template v-if="currentUser">
                    <router-link :to="{ name: 'posts.create' }" text data-test-id="Add sighting link" class="button-placer">
                        <base-button tag="span" color="white" style="color: var(--color-blue-ocean); height: var(--avatar-size);">
                            <v-icon>add</v-icon>
                            <span class="toolbar-button-label">{{ $t('nav.post') }}</span>
                        </base-button>
                    </router-link>

                    <div class="d-flex align-center">
                        <base-menu v-model="userMenu" :attach="false" eager>
                            <template #activator="{ attrs, on }">
                                <button type="button" :aria-label="$t('settings.profile.accountHeading')" data-test-id="Account menu button" class="button-placer" v-bind="attrs" v-on="on">
                                    <v-badge :value="$store.getters.hasNewActionsPageContent" bordered dot left :offset-x="19" :offset-y="19">
                                        <user-avatar :user="currentUser" :image-only="true" />
                                    </v-badge>
                                </button>
                            </template>

                            <v-card max-width="32ch">
                                <the-account-navigation />
                            </v-card>
                        </base-menu>
                    </div>
                </template>
            </v-toolbar-items>

            <language-callout actual-class="language-callout" />
        </v-app-bar>

        <historical-weather-prompt />

        <survey-requests-prompt-banner />

        <transition name="fade">
          <main class="main-content">
            <span id="main-content-focus-point" class="d-sr-only" tabindex="-1">{{ $t('nav.mainContent') }}</span>
            <slot />
          </main>
        </transition>

        <footer v-if="!hideFooter" class="footer">
          <div class="navigation">
              <v-container fluid class="px-10" fill-height>
                <v-row>
                    <v-col class="d-flex justify-space-between flex-column">
                        <footer-links />
                    </v-col>

                    <v-col cols="auto" class="text-right">
                        <div>
                            <the-language-select />

                            <div>
                                <img
                                    :src="require('@/assets/images/louisiana-entertainment.png')"
                                    alt="Lousiana Entertainment, a division of Louisiana Economic Development"
                                    class="mt-4"
                                    height="45"
                                >
                            </div>
                        </div>
                    </v-col>
                </v-row>
              </v-container>
          </div>
      </footer>
    </heading-level>
</template>

<script lang="ts">
import AuthButton from '@/components/AuthButton.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import HistoricalWeatherPrompt from '@/components/HistoricalWeatherPrompt/Index.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import SurveyRequestsPromptBanner from '@/components/Survey/RequestsPromptBanner.vue';
import TheAccountNavigation from '@/components/TheAccountNavigation.vue';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { CurrentUser } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo: {
        title: 'Community Climate & Weather Journal',
        titleTemplate: 'ISeeChange | %s',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
            },
            {
                property: 'og:title',
                content: 'Community Climate & Weather Journal',
                template: chunk => `ISeeChange - ${chunk}`,
                vmid: 'og:title'
            },
            {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
            },
            {
                property: 'og:site_name',
                content: 'ISeeChange',
                vmid: 'og:site_name'
            },
            {
                property: 'og:description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
                vmid: 'og:description'
            },
            {
                property: 'twitter:card',
                content: 'summary',
                vmid: 'twitter:card'
            },
            {
                property: 'twitter:site',
                content: '@iseechange',
                vmid: 'twitter:site'
            },
            {
                property: 'twitter:title',
                content: 'ISeeChange - Community Climate & Weather Journal',
                vmid: 'twitter:title'
            },
            {
                property: 'twitter:description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
                vmid: 'twitter:description'
            },
        ],
    },
    components: {
        AuthButton,
        FooterLinks,
        Heading,
        HeadingLevel,
        HistoricalWeatherPrompt,
        LanguageCallout,
        PrimaryLogo,
        SurveyRequestsPromptBanner,
        TheAccountNavigation,
        TheLanguageSelect,
        UserAvatar,
    },
    data() {
        return {
            menuOpen: false,
            userMenu: false,
        };
    },
    computed: {
        userChecked(): boolean {
            return this.$store.state.account.autoAuthStatus !== null;
        },
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        hideMainNav(): boolean {
            return !this.currentUser && this.$route.matched.some(record => record.meta.hideMainNavWithNoUser);
        },
        hideFooter(): boolean {
            return Boolean(this.$route.meta?.hideFooter);
        },
        hasClientGroup(): boolean {
            return (this.currentUser?.clientGroups?.length ?? 0) !== 0;
        },
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
    },
});
</script>
<style>
:root {
  --webnavigation--height: 96px;
}
</style>

<style lang="postcss" scoped>
.web-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.web-layout[data-main-nav-hidden] {
  --webnavigation--height: 0px; /* NOTE: The `px` unit is required so we can `calc()` with this value! */
}

.toolbar {
  overflow-x: auto;
  scrollbar-width: thin;
}

.toolbar::-webkit-scrollbar {
  height: 0.3rem;
}

.toolbar::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
}

.button-placer {
    align-items: center;
    display: flex;
    margin-inline-start: 16px;
    text-decoration: none;
}

.toolbar-button-label {
    display: inline-block;
    margin-inline: 12px;
}


.web-layout > .main-content {
  flex-grow: 1;
}

.web-layout {
    padding-top: var(--webnavigation--height);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 250ms ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.logo {
    position: relative;
    bottom: -4px;
}

:deep(.language-callout) {
    border-radius: 3px;
    box-shadow: 0 3px 5px -5px black;
    position: absolute;
    right: var(--spacing-4);
    top: calc(100% + var(--spacing-4));
    width: auto;
}

@media screen and (max-width: 800px) {
    :deep(svg.logo .type) {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    :deep(.v-toolbar__items a.v-btn) {
        padding: 0 10px;
        letter-spacing: 0.75px;
    }
    :deep(.v-toolbar__content) {
        padding: 4px 8px;
    }
}
@media screen and (max-width: 720px) {
    :deep(.v-toolbar__items a.v-btn) {
        padding: 0 8px;
    }
}


.footer {
    background-color: var(--color-secondary);
    color: var(--color-secondary-contrast);
}

</style>

