<template>
<v-dialog v-model="dialogVisibility" max-width="600px">
    <v-card>
        <v-card-title justify-end>
            <div class="t-subtitle">{{ $t('shareDialog.title') }}</div>
            <base-button text icon :aria-label="$t('actions.close')" @click="dialogVisibility = false">
                <v-icon>close</v-icon>
            </base-button>
        </v-card-title>
        <v-card-text>
            <div class="mb-4">
                <h2 class="t-lead">{{ $t('shareDialog.linkHeading') }}</h2>
                <label>
                    <p v-if="post">{{ $t('shareDialog.postLinkBody') }}</p>
                    <copy-text :text="shortPostURL || shareProps.url" />
                </label>
            </div>
            <div v-if="post" class="mb-4">
                <label>
                    <h2 class="t-lead">{{ $t('shareDialog.embedHeading') }}</h2>
                    <copy-text :text="postEmbedHTML" class="mb-3" />
                </label>
            </div>
            <h2 class="t-lead">{{ $t('shareDialog.networkHeading') }}</h2>
            <v-row class="social-links">
                <v-col>
                    <share-network tag="div" network="facebook" v-bind="shareProps">
                        <base-button block color="primary" class="mb-3 mr-2">Facebook</base-button>
                    </share-network>
                </v-col>
                <v-col>
                    <share-network tag="div" network="twitter" v-bind="shareProps">
                        <base-button block color="primary" class="mb-3">Twitter</base-button>
                    </share-network>
                </v-col>
                <v-col>
                    <share-network tag="div" network="linkedin" v-bind="shareProps">
                        <base-button block color="primary" class="mb-3">LinkedIn</base-button>
                    </share-network>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</v-dialog>
</template>
<script lang="ts">
import { Post } from '../types';
import Vue from '@/vueTyped';
import { CLIENT_ORIGIN } from '@/config';
import CopyText from '@/components/CopyText.vue';

export default Vue.extend({
    name: 'TheShareDialog',
    components: {
        CopyText,
    },
    computed: {
        post(): Post | null {
            return this.$store.state.share.content.post ?? null;
        },
        dialogVisibility: {
            get(): boolean {
                return this.$store.state.share.shown;
            },
            set(value: boolean) {
                this.$store.commit('SHOW_SHARE', value);
            }
        },
        shareProps(): object {
            return {
                url: this.post ? this.shareablePostURL : this.$store.state.share.content.url,
                title: this.post?.textBody ? `“${this.post.textBody}”` : this.$store.state.share.content.title ?? '',
                hashtags: 'iseechange,climatechange'
            };
        },
        shortPostURL(): string {
            if (!this.post) {
                return '';
            }
            // We won't get here from the app, that'll use Capacitor's Share plugin,
            // so we can assume the URL will be meaningful.
            return this.shareablePostURL;
        },
        shareablePostURL(): string {
            if (!this.post) {
                return '';
            }
            return `${CLIENT_ORIGIN}/share/sightings/${this.post.id}`;
        },
        postEmbedHTML(): string {
            if (!this.post) {
                return '';
            }
            return [
                `<blockquote class="iseechange-embeddable" data-type="post" data-post-id="${this.post.id}">`,
                `<p><a href="${CLIENT_ORIGIN}/p/${this.post.id}"> ${this.post.textBody} </a></p>`,
                `— <a href="${CLIENT_ORIGIN}/u/${this.post.userObj.id}">${this.post.userObj.firstName} ${this.post.userObj.lastName}</a>`,
                `</blockquote><script async src="https://code.iseechange.org/embeddable.js" charset="utf-8"></${'script'}>`
            ].join('\n');
        },
    },
});
</script>
<style lang="postcss" scoped>
.share {
    justify-content: flex-start;
}
.v-card__title {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
}
</style>
