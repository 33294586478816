<template>
    <div class="base-custom-input" :data-has-error="$slots.error">
        <div v-if="$slots.label" class="mb-2">
            <label :for="inputId" class="top-label">
                <slot name="label" />
            </label>
        </div>

        <slot />

        <div v-if="$slots.error || $slots.hint || $attrs.hint" class="hint-text mt-2">
            <slot name="error" />
            <template v-if="!$slots.error">
                <slot name="hint">
                    <markdown-output v-if="$attrs.hint" :value="$attrs.hint" trusted />
                </slot>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import MarkdownOutput from './MarkdownOutput.vue';

export default Vue.extend({
    components: {
        MarkdownOutput,
    },

    props: {
        inputId: {
            type: String,
            default: null
        }
    },
});
</script>

<style lang="postcss" scoped>
.base-custom-input {}

.top-label {
    color: #0009;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: uppercase;
}

[data-has-error] .top-label {
    animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1); /* Copied from Vuetify. */
    color: var(--color-danger);
}

.hint-text {
    color: #0009;
    font-size: var(--type-small);
    line-height: 1.3;
}

[data-has-error] .hint-text {
    color: var(--color-danger);
}
</style>
