<template>
  <div v-if="$route.meta && $route.meta.isHelper">
    <router-view />
  </div>
  <v-app v-else :class="`platform-${platform}`">
    <component :is="layout" :data-ignore-app-layout="$route.meta && $route.meta.ignoreAppLayout">
      <survey-modal />
      <router-view />
      <the-errors class="app-error-bar" />
      <the-share-dialog />
    </component>
    <div v-if="environmentOverride || apiHostOverride" class="environment-override-warning">
        <div v-if="environmentOverride" class="label">
            Environment override:<br>
            Currently on {{ environmentOverride.is }}<br>
            <button type="button" class="reset-button" @click="removeQueryParam('dangerously-override-environment')">Reset to {{ environmentOverride.shouldBe }}</button>
        </div>

        <div v-if="apiHostOverride" class="label">
            API host override:<br>
            {{ apiHostOverride }}<br>
            <button type="button" class="reset-button" @click="removeQueryParam('dangerously-override-api-host')">Clear</button>
        </div>
    </div>
  </v-app>
</template>
<script lang="ts">
import Vue from '@/vueTyped';
import MobileLayout from '@/layouts/Mobile.layout.vue';
import WebLayout from '@/layouts/Web.layout.vue';
import LoadingLayout from '@/layouts/Loading.layout.vue';
import SurveyModal from '@/components/Survey/Modal.vue';
import TheErrors from '@/components/TheErrors.vue';
import TheShareDialog from '@/components/TheShareDialog.vue';
import smoothscroll from 'smoothscroll-polyfill';
import { RootState } from './types';
import { API_HOST_OVERRIDE, BUILD_ENVIRONMENT, ENVIRONMENT_IN_USE } from './config';

export default Vue.extend({
  components: {
    SurveyModal,
    TheErrors,
    TheShareDialog,
  },
  computed: {
    platform(): string | null {
      return (this.$store.state as RootState).platform;
    },
    layout(): any {
      // TODO: "/" renders before the actual current route, so `LoadingLayout` flashes before the page. Why?
      if (this.$route.meta?.ignoreAppLayout) {
          return 'div';
      } else if (this.platform === null) {
        return LoadingLayout;
      } else {
          const smallScreen = this.$vuetify.breakpoint.xsOnly;
          const isNativeApp = ['ios', 'android'].includes(this.platform);
        if (smallScreen || isNativeApp) {
          return MobileLayout;
        } else {
          return WebLayout;
        }
      }
    },
    environmentOverride(): { is: string, shouldBe: string } | null {
        return ENVIRONMENT_IN_USE === BUILD_ENVIRONMENT ? null : {
            is: ENVIRONMENT_IN_USE,
            shouldBe: BUILD_ENVIRONMENT,
        };
    },
    apiHostOverride(): typeof API_HOST_OVERRIDE {
        return API_HOST_OVERRIDE;
    },
  },
  mounted() {
    // $vuetify.goTo doesn't work horizontally.
    // https://github.com/vuetifyjs/vuetify/issues/5732
    smoothscroll.polyfill();
  },

  methods: {
      removeQueryParam(key: string) {
          const url = new URL(location.href);
          url.searchParams.delete(key);
          location.replace(url.href);
      }
  },
});
</script>
<style lang="postcss" scoped>
/* Override 100vh from Vuetify so the bottom navigation doesn't cover it. */
.app-error-bar.app-error-bar {
  bottom: calc(var(--bottom-bar-offset) + 55px);
  height: auto;
  top: calc(var(--top-bar-offset) + var(--webnavigation--height));
}

[data-ignore-app-layout] {
    display: contents;
}

.environment-override-warning {
    border: 7px double;
    border-radius: 10px;
    bottom: 5px;
    color: red;
    font: bold 1em monospace;
    left: 5px;
    line-height: 1;
    pointer-events: none;
    position: fixed;
    right: 5px;
    text-transform: uppercase;
    top: 5px;
    z-index: 100;
}

.environment-override-warning .label {
    background: black;
    border: 2px solid;
    border-radius: 3px;
    padding: 0.6ch 1ch;
    pointer-events: initial;
    margin: 3px;
    width: max-content;
    white-space: nowrap;
}

.environment-override-warning .reset-button {
    background: red;
    border-radius: 1em;
    color: black;
    margin: 0.2em 0;
    padding: 0.3em 1em;
    text-transform: inherit;
}
</style>
