<template>
    <div class="track">
        <div class="fill" :style="{ '--progress': value / max }">
            <div v-if="$slots.default" class="tip">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {},

    props: {
        value: {
            type: Number,
            required: true,
        },

        max: {
            type: Number,
            required: true,
        },
    },
});
</script>

<style lang="postcss" scoped>
.track {
    background: #8883;
    border-radius: 2em;
    height: 0.6em;
    margin-top: 1em;
    position: relative;
}

.fill {
    background: var(--fill, var(--color-primary));
    border-radius: 2em;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(var(--progress) * 100%);
    transition: width 0.3s;
}

.tip {
    bottom: 100%;
    position: absolute;
    right: 0;
    transform: translateX(calc((1 - var(--progress)) * 100%));
    white-space: nowrap;
}
</style>
