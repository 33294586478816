<template>
    <v-dialog v-model="dialog" max-width="40ch">
        <template #activator="{ attrs, on }">
            <slot :on="on">
                <base-button dark outlined small v-bind="attrs" v-on="on">
                    <v-icon size="14" class="mr-2">language</v-icon>
                    {{ $t('footer.preferredlanguage') }}
                </base-button>
            </slot>
        </template>
        <v-card>
            <v-list>
                <v-subheader @click.alt="showingDevLocale = !showingDevLocale">
                    <h2 class="subtitle-1">{{ $t('footer.preferredlanguage') }}</h2>
                </v-subheader>
                <v-list-item-group v-model="selection" color="primary">
                    <template v-for="locale in $i18n.availableLocales">
                        <v-list-item v-if="locale !== devLocale || showingDevLocale" :key="locale" :value="locale">
                            <v-list-item-content>
                                <v-list-item-title>{{ messages[locale].languages ? messages[locale].languages[locale] : locale }}</v-list-item-title>
                                <v-list-item-subtitle v-if="locale !== $i18n.locale">{{ $t(`languages.${locale}`) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-card-actions>
                <v-spacer />
                <base-button color="primary" text @click="dialog = false">{{ $t('actions.cancel') }}</base-button>
                <base-button color="primary" :disabled="selection === undefined" @click="updateLang">{{ $t('actions.setlanguage') }}</base-button>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { messages, devLocale } from '@/i18n';
import { trackLocale } from '@/tracking';

export default Vue.extend({
    name: 'TheLanguageSelect',
    data() {
        return {
            messages,
            devLocale,
            dialog: false,
            showingDevLocale: false,
            selection: this.$i18n.locale,
        };
    },

    watch: {
        '$i18n.locale': {
            immediate: true,
            handler(locale) {
                // TODO: There's probably a better place for this.
                document.documentElement.lang = locale;
                document.documentElement.classList[locale === devLocale ? 'add' : 'remove']('dev-locale-active');
            },
        }
    },

    methods: {
        updateLang() {
            this.dialog = false;

            if (this.selection === this.$i18n.locale) {
                return;
            }

            this.$i18n.locale = this.selection;
            trackLocale(this.$i18n.locale, true);
        },
    }
});
</script>

<style lang="postcss">
    .dev-locale-active [data-is-prismic-rich-text] {
        opacity: 0.5;
        filter: blur(0.25ch);
    }
</style>
