<template>
  <fieldset>
      <template v-if="$slots.legend">
        <legend>
            <slot name="legend" />
        </legend>

        <div style="clear: left;" />
      </template>

      <slot />

      <div v-if="$slots.hint" class="hint">
          <slot name="hint" />
      </div>
  </fieldset>
</template>

<script lang="ts">
export default {};
</script>

<style lang="postcss" scoped>
fieldset {
  border: 0;
}

legend {
    color: rgba(0, 0, 0, 0.6);
    float: left; /* Legend positioning is weird. */
    font-weight: bold;
    font-size: calc(16px * 0.75); /* Mimic a Vuetify input's floating label's scale. */
    text-transform: uppercase;
    width: 100%;
}

.hint {
    color: rgba(0, 0, 0, 0.6);
    font-size: var(--type-small);
    line-height: 1.3;
}
</style>
