<template>
    <the-language-select v-if="shouldCallOutLanguageSelect" v-slot="{ attrs, on }">
        <div :class="`${actualClass} main-block`">
            <button type="button" class="activator" v-bind="attrs" v-on="on">
                {{ $t('nav.speakAnotherLanguage') }}
            </button>

            <button type="button" class="close-button" @click="dismissLanguageCallout">
                <v-icon style="color: inherit;">close</v-icon>
            </button>
        </div>
    </the-language-select>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';

export default Vue.extend({
    name: 'LanguageCallout',

    components: {
        TheLanguageSelect,
    },

    props: {
        actualClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            shouldCallOutLanguageSelect: location.search.includes('show-language-callout') || !localStorage.languageCalloutSeen,
        };
    },

    watch: {
        '$i18n.locale': 'dismissLanguageCallout',
    },

    methods: {
        dismissLanguageCallout() {
            if (this.shouldCallOutLanguageSelect) {
                localStorage.languageCalloutSeen = new Date().toString();
                this.shouldCallOutLanguageSelect = false;
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.main-block {
    background: #f6d258;
    color: #48556b;
    display: flex;
    font-weight: bold;
    font-style: italic;
    position: relative;
}

button:hover {
    background: #fff3;
}

.activator {
    flex: 1 1;
    padding: 0.5em 1.5em 0.5em 2.5em;
}

.close-button {
    border-left: 1px solid #0001;
    flex: 0 0 1em;
    padding: 0.5em;
}
</style>
