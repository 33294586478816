<template>
    <router-link v-if="iconGoesHome" :to="homeRoute" class="home-link" aria-label="ISeeChange home">
        <primary-logo :icon="iconOnly" :height="36" class="logo" />
    </router-link>

    <base-button v-else dark icon class="back-button" aria-label="Go back" @click="handleClick">
        <v-icon>arrow_back</v-icon>
    </base-button>
</template>

<script lang="ts">
import RouteNames from '@/router/names';
import Vue from '@/vueTyped';
import PrimaryLogo from './logo/Primary.vue';

const ON_FIRST_LOADED_PAGE = 'ON_FIRST_LOADED_PAGE';
let onFirstLoadedPage = true;
history.replaceState({ [ON_FIRST_LOADED_PAGE]: onFirstLoadedPage }, '');

export default Vue.extend({
    components: {
        PrimaryLogo,
    },

    props: {
        iconOnly: Boolean,
    },

    data() {
        return {
            onFirstLoadedPage,
        };
    },

    computed: {
        iconGoesHome(): boolean {
            const hasBaseRoute = this.$route.meta?.baseRoute;
            return this.onFirstLoadedPage || !hasBaseRoute;
        },

        homeRoute() {
            return { name: RouteNames.HOME };
        },
    },

    watch: {
        $route() {
            try {
                this.onFirstLoadedPage = history.state[ON_FIRST_LOADED_PAGE];
            } catch (error) {
                this.onFirstLoadedPage = false;
            }
        },

        onFirstLoadedPage() {
            onFirstLoadedPage = this.onFirstLoadedPage;
        },
    },

    methods: {
        handleClick() {
            this.$router.back();
        },
    },

});
</script>

<style scoped>
.home-link {
    color: white;
    padding: 8px;
}

.logo {
    display: block;
}

.back-button::before {
    /* Work around the forced "active" class.
    https://github.com/vuetifyjs/vuetify/issues/11149 */
    display: none;
}
</style>
