<template>
    <textarea class="copy-text" :value="text" readonly :style="{ height }" @focus="selectAll" />
</template>
<script lang="ts">
import Vue from '@/vueTyped';

const SCROLLBAR_CLEARANCE = '16px';

export default Vue.extend({
    name: 'CopyText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            lineHeight: '1em',
        };
    },

    computed: {
        rows(): Number {
            if (this.$attrs.rows !== undefined) {
                return parseFloat(this.$attrs.rows);
            } else {
                return this.text.split('\n').length;
            }
        },

        height(): String {
            return `calc(${this.lineHeight} * ${this.rows} + var(--spacing-2) * 2 + ${SCROLLBAR_CLEARANCE})`;
        }
    },

    mounted() {
        this.lineHeight = getComputedStyle(this.$el).lineHeight;
    },

    methods: {
        selectAll() {
            (this.$el as HTMLTextAreaElement).select();
        }
    },
});
</script>
<style lang="postcss" scoped>
    .copy-text {
        background-color: var(--color-light-tint);
        color: var(--color-dark-tint);
        overflow: auto;
        padding: var(--spacing-2);
        white-space: pre;
        width: 100%;
    }
</style>

