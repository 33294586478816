<template>
    <nav v-if="currentUser" class="account" :aria-label="String($t('routes.account'))" data-test-id="Account menu">
        <div class="account__user py-4 mx-4">
            <user-avatar :user="currentUser" link>
                <span class="location">{{ location }}</span>
            </user-avatar>
        </div>

        <v-list class="nav-list">
            <v-list-item>
                <base-button text block color="primary" :to="{ name: 'actions' }">
                    <v-badge :value="$store.getters.hasNewActionsPageContent" dot left :offset-x="-5" :offset-y="13" color="orange">
                        <v-icon v-if="icons" left>shield</v-icon>
                    </v-badge>
                    {{ $t('nav.actions') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <base-button text block color="primary" :to="{ name: 'user.detail', params: { id: currentUser.id } }" data-test-id="User profile link">
                    <v-icon v-if="icons" left>remove_red_eye</v-icon>
                    {{ $t('nav.mySightings') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <base-button text block color="primary" :to="{ name: 'account.profile' }">
                    <v-icon v-if="icons" left>person</v-icon>
                    {{ $t('nav.profile') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <base-button text block color="primary" :to="{ name: 'account.notifications' }">
                    <v-icon v-if="icons" left>notifications</v-icon>
                    {{ $t('nav.notifications') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <base-button text block color="primary" :to="{ name: 'account.sensors' }">
                    <v-icon v-if="icons" left>sensors</v-icon>
                    {{ $t('nav.sensorsAndIntegrations') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <base-button text block color="primary" data-test-id="Log-out button" @click="logout">
                    <v-icon v-if="icons" left aria-hidden="true">logout</v-icon>
                    {{ $t('actions.logout') }}
                </base-button>
            </v-list-item>
        </v-list>
    </nav>
</template>

<script lang="ts">
import UserAvatar from '@/components/UserAvatar.vue';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        UserAvatar,
    },

    props: {
        icons: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        location(): string {
            if (!this.currentUser?.addressComponents) return '';
            try {
                return Array.from(new Set([
                    this.currentUser.addressComponents.city,
                    this.currentUser.addressComponents.place,
                    this.currentUser.addressComponents.state,
                    this.currentUser.addressComponents.country === 'United States' ? null : this.currentUser.addressComponents.country,
                ].filter(Boolean))).join(', ');
            } catch (error) {
                return this.currentUser.shortAddress || '--';
            }
        },
    },

    methods: {
        async logout() {
            await this.$store.dispatch('accountLogOut');
        },
    },
});
</script>

<style scoped>
.account__user {
    border-bottom: 1px solid var(--color-gray-95);
}

.account :deep(.avatar__name) {
    color: var(--color-primary);
}

.v-list :deep(.v-btn) {
    justify-content: left;
}
</style>
