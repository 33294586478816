:root {
    --top-bar-offset: 0px;
    --bottom-bar-offset: 0px;
}
.platform-ios,
.platform-android {

}
.platform-ios {
    --top-bar-offset: env(safe-area-inset-top);
    --bottom-bar-offset: env(safe-area-inset-bottom);
}
.platform-android {
}

.v-app-bar.v-app-bar--fixed {
    top: var(--top-bar-offset) !important;
}
