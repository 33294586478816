<template>
    <v-snackbar
        v-if="alert && !resetting"
        :value="true"
        :top="!$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :color="alert.type"
        :timeout="alert.timeout !== undefined ? alert.timeout : defaultTimeout"
        @input="dismissFirst"
    >
        <markdown-output :value="$te(alert.message) ? $t(alert.message) : alert.message" inline class="message" />

        <template #action="{ attrs }">
            <v-btn dark icon :aria-label="$t('actions.close')" v-bind="attrs" @click="dismissFirst">
                <v-icon>close</v-icon>
            </v-btn>

            <template v-if="alerts.length > 1">{{ alerts.length }}</template>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import MarkdownOutput from './MarkdownOutput.vue';

export default Vue.extend({
    components: { MarkdownOutput },

    data() {
        return {
            resetting: false,
            defaultTimeout: 12000,
        };
    },

    computed: {
        alerts(): any[] {
            return this.$store.state.alerts;
        },

        alert(): any {
            return this.alerts[0];
        },
    },

    methods: {
        async dismissFirst() {
            this.resetting = true;
            this.$store.dispatch('dismissAlert');
            await this.$nextTick();
            this.resetting = false;
        }
    }
});
</script>

<style lang="postcss" scoped>
.message {
    font-size: var(--type-base);
    line-height: 1.4;
}
.message :deep(a) {
    color: inherit;
}
</style>
