<template>
    <div class="slippy-carousel-wrapper" :style="`--carousel-height: ${averageVisiblePanelHeight};`" @load.capture="handleLoad">
        <div class="main-row">
            <div v-if="prevNextArrows" class="arrow-column" :data-floating="floatingArrows">
                <base-button x-small color="primary" fab :disabled="panelCount === 0 || internalValue === 0" @click="scrollTo(internalValue - 1)">
                    <v-icon>chevron_left</v-icon>
                </base-button>
            </div>

            <div ref="panels" v-scroll.self="handleScroll" class="panels-column">
                <slot />
            </div>

            <div v-if="prevNextArrows" cols="auto" class="arrow-column" :data-floating="floatingArrows">
                <base-button x-small color="primary" fab :disabled="panelCount === 0 || internalValue === panelCount - 1" @click="scrollTo(internalValue + 1)">
                    <v-icon>chevron_right</v-icon>
                </base-button>
            </div>
        </div>

        <template v-if="dotPosition !== 'hidden'">
            <div class="dots" :data-position="dotPosition">
                <button
                    v-for="i in panelCount"
                    :key="i"
                    class="dot-button"
                    type="button"
                    :aria-label="`Show panel ${i} of ${panelCount}`"
                    @click="scrollTo(i - 1)"
                >
                    <v-icon class="dot-icon" :data-active="i - 1 === internalValue">fiber_manual_record</v-icon>
                </button>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import scroll from 'vuetify/lib/directives/scroll';

export default Vue.extend({
    directives: { scroll },

    props: {
        value: {
            type: Number,
            default: 0,
        },

        dotPosition: {
            type: String,
            default: 'hidden', // Or "top" or "bottom"
        },

        prevNextArrows: {
            type: Boolean,
            default: false,
        },

        floatingArrows: {
            type: Boolean,
            default: false,
        },

        fitEachPanel: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            panelCount: 0,
            internalValue: -1,
            averageVisiblePanelHeight: 'auto',
            recentExternalValueChanges: 0,
            recentScrollEvents: 0,
        };
    },

    watch: {
        value(value: number) {
            this.recentExternalValueChanges += 1;
            this.internalValue = value;

            setTimeout(() => {
                this.recentExternalValueChanges -= 1;
            }, 50);
        },

        async internalValue(internalValue: number) {
            if (this.recentExternalValueChanges === 0) {
                this.$emit('input', internalValue);
            }
        },
    },

    mounted() {
        this.countPanels();
        this.internalValue = this.value;
    },

    updated() {
        this.countPanels();
    },

    methods: {
        countPanels() {
            this.panelCount = (this.$refs.panels as HTMLDivElement).children.length;
        },

        scrollTo(panelIndex: number) {
            panelIndex = ((panelIndex % this.panelCount) + this.panelCount) % this.panelCount;
            const carousel = this.$refs.panels as HTMLDivElement;
            const panel = carousel.children[panelIndex] as HTMLElement;
            carousel.scrollLeft = panel.offsetLeft - carousel.offsetLeft - carousel.clientLeft;
        },

        handleLoad(event: Event) {
            const target = event.target as HTMLElement | null;
            if (this.fitEachPanel && target && target.offsetHeight !== 0) {
                this.fitVisiblePanels(this.internalValue);
            }
        },

        handleScroll() {
            const carousel = this.$refs.panels as HTMLDivElement;

            const scrollX = Math.abs(carousel.scrollLeft);
            const scrollAmount = scrollX / (carousel.scrollWidth - carousel.clientWidth);
            const visiblePanelIndices = scrollAmount * (carousel.children.length - 1);

            const centerPanelIndex = Math.round(visiblePanelIndices);
            this.internalValue = centerPanelIndex;

            this.recentScrollEvents += 1;

            setTimeout(() => {
                this.recentScrollEvents -= 1;

                if (this.recentScrollEvents === 0) {
                    this.handleApproximateScrollEnd();
                }
            }, 100);
        },

        handleApproximateScrollEnd() {
            if (this.fitEachPanel) {
                this.fitVisiblePanels(this.internalValue);
            }

            this.checkFocus();
        },

        // Note, this supports decimals but browsers behave inconsistently when changing height while scrolling.
        fitVisiblePanels(visiblePanelIndex: number) {
            const carousel = this.$refs.panels as HTMLDivElement;

            let startPanel = carousel.children[Math.floor(visiblePanelIndex)] as HTMLElement;
            let endPanel = carousel.children[Math.ceil(visiblePanelIndex)] as HTMLElement | undefined ?? startPanel;

            const endPanelWeight = visiblePanelIndex % 1;
            const startPanelWeight = 1 - endPanelWeight;

            startPanel.style.maxHeight = '1px';
            endPanel.style.maxHeight = '1px';

            const averageHeight = startPanel.scrollHeight * startPanelWeight + endPanel.scrollHeight * endPanelWeight;
            this.averageVisiblePanelHeight = `${averageHeight}px`;

            startPanel.style.maxHeight = '';
            endPanel.style.maxHeight = '';
        },

        checkFocus() {
            if (
                document.activeElement &&
                this.$el.contains(document.activeElement) &&
                document.activeElement.getBoundingClientRect().left as number < 0
            ) {
                const panel = (this.$refs.panels as HTMLDivElement).children[Math.round(this.internalValue)] as HTMLElement | undefined;
                if (panel) {
                    const tabIndexWas = panel.tabIndex;
                    panel.tabIndex = -1;
                    panel.focus();
                    panel.tabIndex = tabIndexWas;
                    console.log(panel, document.activeElement);
                }
            }
        }
    },
});
</script>

<style type="postcss" scoped>
.slippy-carousel-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.main-row {
    display: flex;
    flex-grow: 1;
}

.arrow-column {
    align-self: var(--carousel-arrow-top, center);
    margin-top: var(--carousel-arrow-top, FALL_BACK_TO_ALIGN_SELF);
    z-index: 2;
}

.arrow-column:first-child {
    margin-right: var(--spacing-2);
}

.arrow-column:last-child {
    margin-left: var(--spacing-2);
}

.arrow-column[data-floating] {
    position: absolute;
}

.arrow-column:first-child[data-floating] {
    left: var(--spacing-4);
}

.arrow-column:last-child[data-floating] {
    right: var(--spacing-4);
}

.panels-column {
    display: flex;
    flex-grow: 1;
    height: var(--carousel-height);
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    transition: height 0.1s;
}

.panels-column::-webkit-scrollbar {
    height: 0;
}

.panels-column > * {
    flex: 1 0 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.dots {
    display: flex;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.dots[data-position="top"] {
    top: env(safe-area-inset-top);
}

.dots[data-position="bottom"] {
    bottom: env(safe-area-inset-bottom);
}

.dot-button {
    color: inherit;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 15px 0;
}

.dot-icon {
    color: var(--inactive-dot-color, inherit) !important;
    font-size: 1em !important;
}

.dot-icon[data-active] {
    color: var(--active-dot-color, var(--color-primary, red)) !important;
}
</style>
