<template>
    <select v-if="showing" aria-label="Experiment groups" @change="handleChange">
        <option selected>⌥</option>

        <template v-for="[key, values, group] in splits">
            <option :key="`${key}-group`">{{ key }}:</option>
            <option v-for="value in values" :key="value" :value="`${key}=${value}`">
                {{ value === group ? '☑ ' : '&emsp;' }}
                {{ value }}
            </option>
        </template>
    </select>
</template>

<script lang="ts">
import { experiments, experimentsState } from '@/boot/set-up-experiments';
import { BUILD_ENVIRONMENT } from '@/config';
import Vue from '@/vueTyped';

export default Vue.extend({
    computed: {
        showing() {
            const notProduction = BUILD_ENVIRONMENT !== 'production';
            const userIsStaff = this.$store.state.account.currentUser?.email.includes('@iseechange.');
            const queryIsSet = new URLSearchParams(location.search).has('show-experiments');
            return  notProduction || userIsStaff || queryIsSet;
        },

        splits() {
            return Object.entries(experiments).map(([key, values]) => {
                return [key, values, experimentsState[key as keyof typeof experimentsState]] as const;
            });
        },
    },

    methods: {
        handleChange(event: Event) {
            if (!(event.currentTarget instanceof HTMLSelectElement)) return;
            event.preventDefault();
            const [key, value] = event.currentTarget.value.split('=');
            event.currentTarget.selectedIndex = 0;
            if (!value) return;
            const url = new URL(location.href);
            url.searchParams.set(`experiments.${key}`, value ?? '');
            location.replace(url);
        },
    },
});
</script>

<style scoped>
select {
    color: inherit;
    width: 1.7em;
}
</style>
