<template>
    <div class="change-graphs-root" :data-colder="colder">
        <figure>
            <div class="bars">
                <div class="expected bar-container">
                    {{ Math.round(expected) }}°
                    <div class="bar" />
                </div>

                <div class="change bar-container">
                    {{ Math.abs(roundDifference) }}°

                    <div class="arrow-container">
                        <svg
                            width="20"
                            height="200"
                            viewBox="-3 -3 26 206"
                            :preserveAspectRatio="`xMidY${colder ? 'Max' : 'Min'} slice`"
                            class="arrow"
                        >
                            <path
                                d="
                                    M 10, 0 L 10 200
                                    M 0, 12 L 10, 0 L 20 12
                                    M 0, 188 L 10, 200 L 20 188
                                "
                                fill="none"
                                stroke="currentColor"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        />
                        </svg>
                    </div>
                </div>

                <div class="actual bar-container">
                    {{ Math.round(actual) }}°
                    <div class="bar" />
                </div>
            </div>

            <figcaption v-if="$slots.default">
                <slot />
            </figcaption>
        </figure>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        expected: {
            type: Number,
            required: true,
        },

        actual: {
            type: Number,
            required: true,
        },

        related: {
            type: Array as () => number[],
            default: () => [],
        },
    },

    computed: {
        colder(): boolean {
            return this.actual < this.expected;
        },

        roundDifference(): number {
            return Math.round(this.actual) - Math.round(this.expected);
        },
    }
});
</script>

<style lang="postcss" scoped>
.change-graphs-root {
    display: inline-block;
    text-align: center;
    vertical-align: bottom;
}

.bars {
    align-items: flex-end;
    display: inline-grid;
    gap: 1ch;
    grid-template-areas:
        /* Hotter, by default */
        "change actual"
        "expected actual";
}

[data-colder] .bars {
    grid-template-areas:
        "expected change"
        "expected actual";
}

.bar-container {
    align-items: center;
    color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    position: relative;
}

.bar {
    background: currentColor;
    border-radius: 2em;
    box-sizing: content-box;
    content: "";
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    margin-inline: auto;
    width: 1.5em;
}

.expected {
    color: var(--color-medium-shade);
    grid-area: expected;
}

.expected .bar {
    height: calc(3em * var(--expected-multiplier, 1));
}

.actual {
    grid-area: actual;
}

.actual .bar {
    height: calc(3em * var(--actual-multiplier, 1));
}

.change {
    color: var(--color-danger);
    font-weight: bold;
    grid-area: change;
    margin-top: 1em;
    position: relative;
}

.arrow-container {
    height: calc(0.5em + 1em * var(--diff-multiplier, 1));
    position: relative;
    width: 1.5em;
}

.arrow {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

[data-colder] .change {
    color: var(--color-primary);
}

figcaption {
    margin-top: var(--spacing-2);
    white-space: nowrap;
}
</style>
